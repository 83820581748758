import clx from 'classnames';
import { useLocation } from 'react-router-dom';

import { ROUTES } from '../../../routes/constants';
import useWindowSize from '../../../utils/useWindowSize';
import NavBar from '../NavBar';
import styles from './PageWrapper.module.scss';

const PageWrapper = ({
	children, user, isAuthenticated, isLoading
}) => {
	const { pathname } = useLocation();
	const { isMobile } = useWindowSize();

	return (<div className={clx(styles['page-wrapper'],
		{ [styles['page-wrapper__no-nav']]: pathname === ROUTES.ESTABLISHMENTS })}>
		{(!isMobile || pathname !== ROUTES.ESTABLISHMENTS) &&
			<NavBar
				user={user}
				isAuthenticated={isAuthenticated}
				isLoading={isLoading}
				pathname={pathname}
				isMobile={isMobile}
				routes={ROUTES}
			/>}
		<div className={styles.section__wrapper}>
			{children}
		</div>
	</div>);
};

export default PageWrapper;
