import 'react-loading-skeleton/dist/skeleton.css';

import Skeleton from 'react-loading-skeleton';

const SkeletonLine = ({
	width = 80, height = 20, baseColor = '#ab74f91f', highlightColor = '#0eedff17'
}) => (
	<Skeleton
		style={{
			margin: '0 5px'
		}}
		width={width} height={height}
		baseColor={baseColor}
		highlightColor={highlightColor} />
);

export default SkeletonLine;
