import { useEffect, useState } from 'react';

function useWindowSize() {
	const [isLoading, setIsLoading] = useState(true);
	const [isMobile, setIsMobile] = useState(false);
	const [isMiddleSize, setIsMiddleSize] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 480) {
				if (!isMobile) {
					setIsMiddleSize(false);
					setIsMobile(true);
				}
			} else if (window.innerWidth > 480 && window.innerWidth < 1024) {
				if (!isMiddleSize) {
					setIsMobile(false);
					setIsMiddleSize(true);
				}
			} else {
				// eslint-disable-next-line no-lonely-if
				if (isMobile) {
					setIsMobile(false);
				}
				if (isMiddleSize) {
					setIsMiddleSize(false);
				}
			}
			setIsLoading(false);
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	});

	return {
		isMobile, isMiddleSize, isLoading
	};
}

export default useWindowSize;
