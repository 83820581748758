import clx from 'classnames';
import React from 'react';

import styles from './Switch.module.scss';

const Switch = ({
	field, id, form, width = '90px', height = '45px', ...props
}) => (
	<label
		className={clx(styles['switch__wrapper'], { [styles['switch__active']]: field?.value })}
		style={{
			width,
			height,
		}}
	>
		<input {...field} id={id} type="checkbox"
			checked={field?.value} className={styles['switch__field']} {...props} />
		<span
			className={styles['switch-button']}
			style={{
				width: `calc(${height} - 6px)`,
				height: `calc(${height} - 6px)`,
			}}
		/>
	</label>
);

export default Switch;
