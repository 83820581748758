import { Link } from 'react-router-dom';

import { ROUTES } from '../../../../routes/constants';
import styles from './AuthNavLink.module.scss';

const AuthNavLink = ({ isLogin }) => (
	<p className={styles['navigate-link']}>
		{
			isLogin ?
				<>
					Non hai un profilo?&nbsp;
					<Link to={ROUTES.SIGN_UP}>Crea un nuovo profilo</Link>
				</> :
				<>
					Hai già un profilo?&nbsp;
					<Link to={ROUTES.SIGN_IN}>Accedi al tuo profilo</Link>
				</>
		}
	</p>
);

export default AuthNavLink;
