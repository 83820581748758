import * as yup from 'yup';

export const PROFILE_EDIT_VALIDATION_SCHEMA = yup.object().shape({
	email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
	nome: yup.string().required('Nome is required'),
	dateBirth: yup.date().required('Date of birth is required'),
	city: yup.string().required('City is required'),
});

export const PROFILE_EDIT_INITIAL_SCHEMA = yup.object().shape({
	email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
	password: yup.string().min(3, 'Password is Troppo corta - should be 3 chars minimum').max(100, 'Your password is very big!').required('Password is required'),
	nome: yup.string().required('Nome is required'),
	age: yup.number().required('Age is required'),
	city: yup.string().required('City is required'),
});
