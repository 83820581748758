import clx from 'classnames';
import { Link } from 'react-router-dom';

import LogoImg from '../../../../assets/svg/logo.svg';
import { ROUTES } from '../../../../routes/constants';
import styles from './AuthPageWrapper.module.scss';

const AuthPageWrapper = ({ children, isMobile }) => (
	<section className={clx(styles.auth__wrapper, { [styles['auth-wrapper__mobile']]: isMobile })}>
		<Link to={ROUTES.HOME} className={styles.logo__link}>
			<img src={LogoImg} alt='logo' className={styles.logo} />
		</Link>
		{children}
	</section>
);

export default AuthPageWrapper;
