import clx from 'classnames';
import { useEffect, useState } from 'react';

import BinIcon from '../../../assets/svg/cross.svg';
import UploadIcon from '../../../assets/svg/eye.svg';
import { fileReaderToBase64 } from '../../../helpers';
import { fileValidator } from '../../../utils/dragAndDrop';
import DragAndDropContainer from './DragAndDropContainer';
import styles from './UploadFileModal.module.scss';

const UploadFileModal = ({
	uploadFile, setUploadFile, isProfileImg = false, isMobile
}) => {
	const [error, setError] = useState(false);
	const fileTypes = 'image/png, image/jpg, image/jpeg';

	useEffect(() => {
		if (error) {
			setTimeout(() => setError(false), 3000);
		}
	}, [error]);

	return (
		<div className={clx(styles['upload-file__wrapper'], { [styles['upload-file__mobile']]: isMobile })}>
			{uploadFile ?
				(
					<div className={styles['upload-image__wrapper']}>
						<img className={clx(styles['upload-image'], { [styles['upload-image__profile']]: isProfileImg })} src={uploadFile} alt="upload" />
						<div className={styles.remove__btn} role="button" onClick={() => setUploadFile('')}>
							<img src={BinIcon} alt="bin" />
						Delete
						</div>
					</div>
				) :
				(
					<>
						<DragAndDropContainer setUploadFile={setUploadFile} setError={setError}>
							<div className={styles['upload-file__btn']}>
								<label htmlFor="file" className={styles['btn__label']}>
									<img src={UploadIcon} alt="upload" />
									<span>UPLOAD</span>
									{error && <p className={styles.error}>{error}</p>}
								</label>
								<input
									style={{
										width: '0.1px',
										height: '0.1px',
										opacity: '0',
										overflow: 'hidden',
										position: 'absolute',
										zIndex: '-1',
									}}
									onChange={async e => {
										e.persist();
										const file = Object.values(e.target.files);

										if (file?.length) {
											const { errVal } = fileValidator(file, { fileSizeMBLimit: 60 });

											if (errVal) {
												setError(errVal);
												return;
											}

											const base64FileUrl = await fileReaderToBase64(file);

											setUploadFile(base64FileUrl);
											e.target.value = '';
										}
									}}
									name="file"
									id="file"
									type="file"
									accept={fileTypes}
								/>
							</div>
						</DragAndDropContainer>
						<p className={styles.preview__text}>Max 10 MB</p>
					</>
				)}
		</div>
	);
};

export default UploadFileModal;
