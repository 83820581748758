import {
	Navigate, useLocation
} from 'react-router-dom';

import { ROUTES } from '../constants';

export const PrivateRoute = ({
	component: RouteComponent, isAuthenticated
}) => {
	let location = useLocation();

	if (!isAuthenticated) {
		return <Navigate to={ROUTES.HOME} state={{ from: location }} />;
	}

	return <RouteComponent />;
};

