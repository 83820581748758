
import 'swiper/swiper-bundle.min.css';

import clx from 'classnames';
import SwiperCore, {
	Navigation, Pagination
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as ArrowIcon } from '../../../assets/svg/arrow-slide.svg';
import styles from './Carousel.module.scss';

SwiperCore.use([Navigation, Pagination]);

const Carousel = ({
	isMobile,
	slides,
	slidesPerView = 4,
	slidesPerViewMobile = 2.3,
	noNav = true,
	showPagination = false,
	isCustomPagination = false,
	spaceBetween = 10
}) => {
	const hideArrows = noNav || isMobile || slidesPerView >= slides?.length;
	const customPagination = {
		clickable: true,
		type: 'custom',
		renderCustom(swiper, current, total) {
			return "<p class='custom-swiper-pagination'>" + current + '/' + total + '</p>';
		}
	};
	return (
		<Swiper
			pagination={showPagination &&
				(isCustomPagination ?
					customPagination :
					{
						type: 'fraction',
					})}
			grabCursor={true}
			slidesPerView={isMobile ? slidesPerViewMobile : slidesPerView}
			slidesPerColumnFill="row"
			spaceBetween={spaceBetween}
			threshold={2}
			loop={false}
			className={styles['carousel-list']}
			navigation={{
				nextEl: '.swiper-next-btn',
				prevEl: '.swiper-prev-btn'
			}}
		>
			{slides?.map((slide, idx) => (
				<SwiperSlide key={idx}>
					{slide}
				</SwiperSlide>
			))}
			<div className={clx('swiper-prev-btn', { 'swiper-prev-btn-hidden': hideArrows })}>
				<ArrowIcon />
			</div>
			<div className={clx('swiper-next-btn', { 'swiper-next-btn-hidden': hideArrows })}>
				<ArrowIcon />
			</div>
		</Swiper>
	);
};

export default Carousel;
