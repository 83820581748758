import { useLazyQuery, useMutation } from '@apollo/client';
import clx from 'classnames';
import {
	useEffect, useMemo, useState
} from 'react';
import { Outlet } from 'react-router-dom';

import useWindowSize from '../../../utils/useWindowSize';
import styles from './ProfileSettings.module.scss';

const ProfileSettings = ({ children }) => {
	const { isMobile } = useWindowSize();
	return (
		<section
			className={clx(styles.establishments__section, {
				[styles['establishments-section__mobile']]: isMobile,
			})}
		>
			{children}
		</section>
	);
};

export default ProfileSettings;
