import { gql } from '@apollo/client';

export const ADD_TO_FAVOURITE = gql`
mutation markAsFavorite ($establishmentId: Int!) {
    markAsFavorite (establishmentId: $establishmentId) {
        success
				establishmentId
    }
}
`;
export const REMOVE_FROM_FAVOURITE = gql`
mutation unmarkFavorite ($establishmentId: Int!) {
    unmarkFavorite (establishmentId: $establishmentId) {
        success
				establishmentId
    }
}
`;
