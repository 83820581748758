import * as yup from 'yup';

export const SIGNIN_INITIAL_VALUES = {
	email: '',
	password: '',
};

export const SIGNIN_VALIDATION_SCHEMA = yup.object().shape({
	email: yup
		.string('Enter your email')
		.email('Enter a valid email')
		.required('Email is required'),
	password: yup.string()
		.min(3, 'Password is Troppo corta - should be 3 chars minimum')
		.max(100, 'Your password is very big!')
		.required('Password is required'),
});

export const SIGNUP_INITIAL_VALUES = {
	firstName: '',
	email: '',
	password: '',
	passwordConfirmation: '',
	dateBirth: '',
	accompanied: false,
	address: '',
	disabilityIds: [],
	facilityIds: []
};

export const SIGNUP_STEPS = [
	{
		step: 1,
		isArray: true,
		requiredFields: ['disabilityIds']
	},
	{
		step: 2,
		isArray: true,
		requiredFields: ['facilityIds']
	},
	{
		step: 3,
		requiredFields: []
	},
	{
		step: 4,
		requiredFields: ['firstName', 'email', 'password', 'passwordConfirmation', 'dateBirth']
	},
];

export const SIGNUP_VALIDATION_SCHEMA = yup.object().shape({
	disabilityIds: yup
		.array()
		.min(1, 'Select at least one disability')
		.required('Select at least one disability'),
	facilityIds: yup
		.array()
		.min(1, 'Select at least one facility')
		.required('Select at least one facility'),
	firstName: yup
		.string('Enter your nickname')
		.required('Nickname is required'),
	email: yup
		.string('Enter your email')
		.email('Enter a valid email')
		.required('Email is required'),
	dateBirth: yup
		.string('Choose your birth date')
		.required('Choose your birth date'),
	password: yup.string()
		.min(8, 'The password must contain at least 8 characters')
		.max(100, 'Your password is very big!')
		.required('Password is required'),
	passwordConfirmation: yup.string()
		.oneOf([yup.ref('password'), null], 'Le password devono coincidere').min(8, 'La password è troppe breve')
		.max(100, 'La password è troppo lunga!')
		.required('Attenzione! La password è errata'),
});

export const FORGOT_PWD_VALIDATION_SCHEMA = yup.object().shape({
	email: yup
		.string('Enter your email')
		.email('Enter a valid email')
		.required('Email is required'),
});

const PASSWORD_SCHEMA = {
	password: yup.string()
		.min(8, 'The password must contain at least 8 characters')
		.max(100, 'Your password is very big!')
		.required('Password is required'),
	passwordConfirmation: yup.string()
		.oneOf([yup.ref('password'), null], 'Le password devono coincidere').min(8, 'La password è troppe breve')
		.max(100, 'La password è troppo lunga!')
		.required('Attenzione! La password è errata'),
};

export const RESET_PWD_VALIDATION_SCHEMA = yup.object().shape(PASSWORD_SCHEMA);

export const CHANGE_PWD_VALIDATION_SCHEMA = yup.object().shape({
	oldPassword: yup.string()
		.min(8, 'The password must contain at least 8 characters')
		.max(100, 'Your password is very big!')
		.required('Password is required'),
	...PASSWORD_SCHEMA
});

