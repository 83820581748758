import { useQuery } from '@apollo/client';
import clx from 'classnames';
import {useRef, useState} from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import BackArrowIcon from '../../../../../assets/svg/arrow.svg';
import EnvelopeImage from '../../../../../assets/svg/envelope-fill.svg';
import GlobeImage from '../../../../../assets/svg/globe.svg';
import HeartFillImage from '../../../../../assets/svg/heart.svg';
import HeartImage from '../../../../../assets/svg/heart-outlined.svg';
import PhoneImage from '../../../../../assets/svg/phone.svg';
import Carousel from '../../../../Common/Carousel';
import GoogleMapContainer from '../../../../Common/GoogleMapContainer';
import SkeletonLine from '../../../../Common/SkeletonLine';
import { GET_ALL_TROVERA } from '../../api/queries';
import { ESTABLISHMENT_ICON_COLORS } from '../../constants';
import EstablishmentGallery from './containers/EstablishmentGallery';
import styles from './EstablishmentDetails.module.scss';


const EstablishmentDetails = () => {
	const {
		isMobile,
		myref,
		isLoading,
		establishmentsDetails,
		establishmentId,
		removeFromFavourite,
		addToFavourite,
		activeCategory,
		userLocation
	} = useOutletContext();
	const navigate = useNavigate();
	const { data: troveraResult, loading } = useQuery(GET_ALL_TROVERA, {
		variables: {
			filter: {
				getTrovera: true,
				getTroveraToEstablishment: true,
				idEstablishment: [+establishmentId],
			}
		}
	});
	function handleClick(cat) {
		myref.current[cat.typologyId].click();
	}
	const establishment = establishmentsDetails?.establishment || {};
	const {
		buildingNumber, city, lat, lng, street
	} = establishment?.address || {};
	const [isGalleryCarouselOpen, setIsGalleryCarouselOpen] = useState(false);
	const [isDetailsCarouselOpen, setIsDetailsCarouselOpen] = useState(false);
	return (
		<>
			{!isLoading ?
				<div className={clx(
					styles['establishment__details'],
					{ [styles['establishment-details__mobile']]: isMobile }
				)}>
					<div className={styles['details__info']}>
						{isMobile && <div className={styles['gallery-carousel__mobile']}>
							<Carousel
								spaceBetween={10}
								showPagination={true}
								isCustomPagination={true}
								isMobile={isMobile}
								slidesPerView={1.1}
								slidesPerViewMobile={1.1}
								slides={establishment?.gallery?.map(photo => (
									<div key={photo?.id}
										className={clx(
											styles['photo-slide']
										)}>
										<img src={photo?.url} alt='establishment' />
									</div>
								))}
							/></div>}
						<img src={BackArrowIcon} alt='back' onClick={() => navigate(-1)}
							className={styles.back__arrow} />
						<header>

							<h2 className={styles.item__categories}>
								{establishment?.typologiesAndCategories?.map((category, idx) =>
									<span onClick={() => handleClick(category)} key={idx}>{category?.name}</span>
								)}
							</h2>
							<img src={establishment?.isFavorite ? HeartFillImage : HeartImage}
								className={styles.btn__favourite}
								alt='favourite'
								onClick={async () => {
									if (establishment?.isFavorite) {
										await removeFromFavourite({
											variables: {
												establishmentId: +establishment?.id
											}
										});
									} else {
										await addToFavourite({
											variables: {
												establishmentId: +establishment?.id
											}
										});
									}
								}}
							/>
						</header>
						<h1 className={styles.item__name}>{establishment?.name}</h1>
						<p className={styles.item__address}>
							<span className={styles.address__details}>{city}, {street}, {buildingNumber}</span>
							&nbsp;&nbsp;&#8226;&nbsp; &nbsp;
							<span className={styles.link__map} onClick={() => {
								if (userLocation) {
									window.open(
										`https://www.google.com/maps/dir/?api=1&origin=${userLocation?.lat},${userLocation?.lng}&destination=${lat},${lng}`,
										'_blank',
									);
								}
							}}>
								Indicazioni stradali
							</span>
						</p>
						<div className={styles.details__lists}>
							<ul className={styles['trovera-list']}>
								{loading ?
									<SkeletonLine width={isMobile ? '100%' : '150px'} /> :
									<>
										{troveraResult?.GetAllTrovera?.trovera?.map(item => (
											<li key={item?.id}>{item?.name || ''}</li>
										))
										}
									</>
								}
							</ul>
							<div className={styles['contacts']}>
								<p className={styles['contacts__item']}>
									<img src={PhoneImage} alt='phone' />
									{establishment?.phone || '---'}
								</p>
								<p className={styles['contacts__item']}>
									<img src={EnvelopeImage} alt='email' />
									{establishment?.email || '---'}
								</p>
								<p className={styles['contacts__item']}>
									<img src={GlobeImage} alt='website' />
									{establishment?.website || '---'}
								</p>
							</div>
						</div>
						{!isMobile && <div className={styles.item__gallery}>
							{
								establishment?.gallery?.length &&
								<>
									<div className={styles['cover-image__wrapper']}>
										<img
											src={establishment?.gallery?.find(photo => +photo?.id === +establishment?.cover?.id)?.url ||
												establishment?.gallery?.[0]?.url
											}
											alt='gallery' />
									</div>
									<div className={styles['gallery__images']}>
										{establishment?.gallery?.length > 1 && (
											<div className={styles['gallery__first-row']}>
												<img
													src={establishment?.gallery?.[1]?.url}
													alt='gallery' />
												{establishment?.gallery?.[2]?.url && <img
													src={establishment?.gallery?.[2]?.url}
													alt='gallery' />}
											</div>
										)}
										{establishment?.gallery?.length > 3 && (
											<div className={styles['gallery__second-row']}>
												{establishment?.gallery?.[3]?.url && <img
													src={establishment?.gallery?.[3]?.url}
													alt='gallery' />}
												{establishment?.gallery?.length > 4 && <div
													className={styles['open-slider__btn']}
													onClick={() => setIsGalleryCarouselOpen(true)}
												>
													Altre {establishment?.gallery?.length - 4} foto
												</div>}
											</div>
										)}
									</div>
								</>
							}
						</div>
						}
						<div className={styles.item__description}>
							<h3>Informazioni</h3>
							<p>{establishment?.description || '---'}</p>
						</div>
						<div className={styles['gallery__details']}>
							{establishment?.details?.slice(0, 3)?.map(detail => (
								<img
									key={detail?.id}
									src={detail?.image?.url}
									alt='gallery' />
							))}
							{establishment?.details?.length > 3 && <div
								className={styles['open-slider__btn']}
								onClick={() => setIsDetailsCarouselOpen(true)}
							>
								Altre {establishment?.details?.length - 3} foto
							</div>}
						</div>
						{isGalleryCarouselOpen && <EstablishmentGallery
							isMobile={isMobile}
							gallery={establishment?.gallery || []}
							setIsCarouselOpen={setIsGalleryCarouselOpen}
						/>}
						{isDetailsCarouselOpen && <EstablishmentGallery
							isMobile={isMobile}
							gallery={establishment?.details?.map(detail => ({
								description: detail?.description,
								id: detail?.id,
								url: detail?.image?.url,
								title: detail?.title
							})) || []}
							setIsCarouselOpen={setIsDetailsCarouselOpen}
						/>}
					</div>
					<div className={styles.map__wrapper}>
						<GoogleMapContainer
							isMobile={isMobile}
							mapKey={activeCategory}
							lat={lat || 0}
							lng={lng || 0}
							markers={[{
								id: +establishment?.id,
								name: establishment?.name,
								color: ESTABLISHMENT_ICON_COLORS.find(item => +item.id === +activeCategory)?.color,
								lat: lat || 0,
								lng: lng || 0,
								isActive: true
							}]}
						/>
					</div>
				</div> :
				<div className={styles.loader__wrapper}>
					<SkeletonLine width={isMobile ? '100%' : '150px'} />
				</div>
			}
		</>
	);
};

export default EstablishmentDetails;
