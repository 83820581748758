import { useMutation } from '@apollo/client';
import clx from 'classnames';
import {
	Field, Form, Formik
} from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import ArrowIcon from '../../../../assets/svg/arrow.svg';
import { ROUTES } from '../../../../routes/constants';
import useWindowSize from '../../../../utils/useWindowSize';
import PrimaryButton from '../../../Common/Buttons/PrimaryButton';
import SkeletonLine from '../../../Common/SkeletonLine';
import TextField from '../../../Common/TextField';
import { SIGN_IN } from '../../api/mutations';
import { SIGNIN_INITIAL_VALUES, SIGNIN_VALIDATION_SCHEMA } from '../../constants';
import AuthPageWrapper from '../AuthPageWrapper';
import styles from './SignIn.module.scss';

const SignIn = () => {
	const navigate = useNavigate();
	const [signIn, { client, loading }] = useMutation(SIGN_IN);
	const [error, setError] = useState(false);
	const { isMobile } = useWindowSize();

	useEffect(() => {
		const handleBeforeUnload = () => {
			localStorage.removeItem('jwtToken_world4all');
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	return (
		<AuthPageWrapper isMobile={isMobile}>
			<Formik
				initialValues={SIGNIN_INITIAL_VALUES}
				validationSchema={SIGNIN_VALIDATION_SCHEMA}
				onSubmit={async values => {
					await signIn({
						variables: {
							signInInput: {
								login: values?.email,
								password: values?.password,
							},
						},
						onCompleted: data => {
							console.log({ data });
							client.resetStore();
							localStorage.setItem('jwtToken_world4all', data.signIn.jwtToken);
							navigate(ROUTES.HOME);
						},
						onError: error => {
							if (error.message === 'user not found') setError('Password o email non corretti');
							else if (error.message === 'wrong password') setError('Password errata');
							else setError('Errore');
						},
					});
				}}
			>
				{() => (
					<Form className={clx(styles['login__form'], { [styles['login-form__mobile']]: isMobile })}>
						<header>
							<h2 onClick={() => isMobile && navigate(-1)}>
								{isMobile && <img src={ArrowIcon} alt="arrow" />}
								{isMobile ? 'Accedi' : 'Bentornato!'}
							</h2>
						</header>
						<Field name="email" id="email" placeholder="Nickname or Email"
							component={TextField} type="email" />
						<Field
							name="password"
							id="password"
							placeholder="Password"
							showPassword={true}
							component={TextField}
							type="password"
							height={isMobile && '46px'}
						/>
						{error && <span className={styles.login__error}>{error}</span>}
						<footer>
							<Link to={ROUTES.FORGOT_PASSWORD}>Hai dimenticato la password?</Link>
							<PrimaryButton width="100%" text={loading ? <SkeletonLine /> : 'Accedi'} type="submit" />
						</footer>
					</Form>
				)}
			</Formik>
			<div className={clx(styles.nav__links, { [styles['nav-links__mobile']]: isMobile })}>
				<p>
					<span>Non hai un account?</span>&nbsp;<Link to={ROUTES.SIGN_UP}>Registrati</Link>
				</p>
				<p>
					<span>Puoi provare l’app senza iscriverti, ma alcune funzioni saranno limitate.</span>&nbsp;
					<Link to={ROUTES.HOME}>Continua senza account</Link>
				</p>
			</div>
		</AuthPageWrapper>
	);
};

export default SignIn;
