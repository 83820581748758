import { useMutation } from '@apollo/client';
import clx from 'classnames';
import {
	Field, Form, Formik
} from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowIcon from '../../../../assets/svg/arrow.svg';
import EnvelopeIcon from '../../../../assets/svg/envelope.svg';
import { ROUTES } from '../../../../routes/constants';
import useWindowSize from '../../../../utils/useWindowSize';
import PrimaryButton from '../../../Common/Buttons/PrimaryButton';
import SkeletonLine from '../../../Common/SkeletonLine';
import TextField from '../../../Common/TextField';
import { FORGOT_PASSWORD } from '../../api/mutations';
import {
	FORGOT_PWD_VALIDATION_SCHEMA
} from '../../constants';
import AuthPageWrapper from '../AuthPageWrapper';
import styles from './ForgotPassword.module.scss';

const ForgotPassword = () => {
	const navigate = useNavigate();
	const [
		forgotPassword,
		{ loading }
	] = useMutation(FORGOT_PASSWORD);
	const {
		isMobile
	} = useWindowSize();
	const [activeStep, setActiveStep] = useState(1);
	const [userEmail, setUserEmail] = useState('');

	return (
		<AuthPageWrapper isMobile={isMobile}>
			<Formik
				initialValues={{
					email: ''
				}}
				validationSchema={FORGOT_PWD_VALIDATION_SCHEMA}
				onSubmit={async values => {
					await forgotPassword({
						variables: {
							requestResetPasswordInput: {
								email: values?.email
							}
						},
						onCompleted: () => {
							setUserEmail(values?.email);
							setActiveStep(s => s + 1);
						}
					});
				}}
			>
				{() => (
					<Form className={clx(styles['forgot-pwd__form'],
						{ [styles['forgot-pwd-form__mobile']]: isMobile },
						{ [styles['forgot-pwd-form__success']]: +activeStep !== 1 },
					)}>
						{+activeStep === 1 && <header>
							<h2 onClick={() => isMobile && navigate(-1)}>
								{isMobile && <img src={ArrowIcon} alt='arrow' />}
								{isMobile ? 'Recupero password' : 'La tua email'}
							</h2>
							<p>Inserisci l’indirizzo email con cui ti sei iscritto.<br />
								Ti invieremo un link per reimpostare la password.</p>
						</header>}
						<div className={styles.form__body}>
							{activeStep === 1 ?
								<Field name='email' id='email'
									placeholder='Email'
									component={TextField}
									type='email'
								/> :
								<>
									<div className={styles['success__notification-card']}>
										<img src={EnvelopeIcon} alt='envelope' />
										<h3>Ti abbiamo inviato una mail</h3>
										<p>Controlla la tua posta</p>
									</div>
									<p className={styles.success__text}>Se non arriva la mail entro pochi minuti controlla
										la cartella spam, altrimenti premi nuovamente
										il tasto per effettuare un nuovo invio.</p>
								</>
							}
						</div>
						{+activeStep === 1 ?
							<footer>
								<PrimaryButton
									width='100%'
									text={loading ? <SkeletonLine /> : 'Invia link'}
									type='submit'
								/>
								<p>Se non arriva la mail entro pochi minuti controlla
									la cartella spam, altrimenti premi nuovamente
									il tasto per effettuare un nuovo invio.</p>
							</footer> :
							<footer className={styles.success__actions}>
								<PrimaryButton
									width='100%'
									text='Prosegui'
									onClick={() => navigate(ROUTES.SIGN_IN)}
								/>
								<PrimaryButton
									width='100%'
									background='linear-gradient(232.71deg, #0eedff33 -23.85%, #ab74f933 109.37%)'
									text={loading ? <SkeletonLine /> : 'Invia di nuovo'}
									onClick={async () => {
										await forgotPassword({
											variables: {
												requestResetPasswordInput: {
													email: userEmail
												}
											}
										});
									}}
								/>
							</footer>
						}
					</Form>
				)}
			</Formik>
		</AuthPageWrapper >
	);
};

export default ForgotPassword;
