import classNames from 'classnames';

import CloseIcon from '../../../assets/svg/cross.svg';
import styles from './Modal.module.scss';

const Modal = ({
	isModalOpen,
	setIsModalOpen,
	children,
	height = 'auto',
	title = '',
	width = 'fit-content',
	maxWidth = 'none',
	showCloseBtn = false,
	padding = '30px',
	borderRadius = '13px',
	background = '#000',
	classNameWrapper = {},
	className = {},
}) => (
	<>
		{isModalOpen && (
			<div className={classNames(styles.modal__wrapper, classNameWrapper)}>
				<div
					className={classNames(styles.modal, className)}
					style={{
						width,
						maxWidth,
						height,
						padding,
						borderRadius,
						background,
					}}
				>
					{showCloseBtn && (
						<>
							<div className={styles.modal__header} onClick={setIsModalOpen}>
								<img src={CloseIcon} alt="close" />
							</div>
							<div className={styles.modal__title}>{title}</div>
						</>
					)}
					{children}
				</div>
			</div>
		)}
	</>
);

export default Modal;
