import clx from 'classnames';
import GoogleMapReact from 'google-map-react';
import {
	useEffect, useRef, useState
} from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as MoonIcon } from '../../../assets/svg/moon.svg';
import { ReactComponent as PinIcon } from '../../../assets/svg/pin.svg';
import { ReactComponent as SunIcon } from '../../../assets/svg/sun.svg';
import { ROUTES } from '../../../routes/constants';
import styles from './GoogleMapsContainer.module.scss';

const Marker = ({
	markerColor, name, isActive, onClick
}) => (
	<div
		className={clx(styles.map__marker, { [styles.marker__active]: isActive })}
		onClick={onClick}
	>
		<PinIcon style={{ '--fillColor': markerColor }} />
		<span className={styles.marker__name}>{name}</span>
	</div>
);

const GoogleMapContainer = ({
	lat = 41.8719, // Default latitude for Italy
	lng = 12.5674, // Default longitude for Italy
	markers = [],
	height = '100%',
	mapKey,
	setActiveCoord,
	isMobile
}) => {
	const [isDarkMode, setIsDarkMode] = useState(true);
	const navigate = useNavigate();
	const mapRef = useRef();

	useEffect(() => {
		if (mapRef.current) {
			const bounds = new window.google.maps.LatLngBounds();
			// Italy's geographical boundaries
			bounds.extend(new window.google.maps.LatLng(36.0, 6.0)); // Southwest point
			bounds.extend(new window.google.maps.LatLng(47.0, 18.0)); // Northeast point
			mapRef.current.fitBounds(bounds);
		}
	}, []);

	const mapOptions = maps => ({
		scrollwheel: true,
		streetViewControl: !isMobile,
		styles: isDarkMode ? darkModeStyles : [],
		zoomControlOptions: {
			position: maps.ControlPosition.RIGHT_TOP,
		},
		streetViewControlOptions: {
			position: maps.ControlPosition.RIGHT_BOTTOM,
		},
		restriction: {
			latLngBounds: {
				north: 47.0,
				south: 36.0,
				east: 18.0,
				west: 6.0,
			},
			strictBounds: true,
		},
	});

	const darkModeStyles = [
		{
			featureType: 'all',
			elementType: 'geometry',
			stylers: [{ color: '#001b2e' }],
		},
		{
			featureType: 'all',
			elementType: 'labels.text.fill',
			stylers: [
				{ gamma: 0.01 },
				{ lightness: 20 },
				{ weight: '1.4' },
				{ color: '#ffffff' },
			],
		},
		{
			featureType: 'all',
			elementType: 'labels.text.stroke',
			stylers: [
				{ weight: '0.96' },
				{ saturation: '9' },
				{ visibility: 'on' },
				{ color: '#000000' },
			],
		},
		{
			featureType: 'all',
			elementType: 'labels.icon',
			stylers: [{ visibility: 'off' }],
		},
		{
			featureType: 'landscape',
			elementType: 'geometry',
			stylers: [
				{ lightness: 30 },
				{ saturation: '9' },
				{ color: '#012f4d' },
			],
		},
		{
			featureType: 'poi',
			elementType: 'geometry',
			stylers: [{ saturation: 20 }],
		},
		{
			featureType: 'poi.park',
			elementType: 'geometry',
			stylers: [{ lightness: 10 }],
		},
		{
			featureType: 'road',
			elementType: 'geometry',
			stylers: [
				{ lightness: 10 },
				{ saturation: -30 },
				{ color: '#013f66' },
			],
		},
		{
			featureType: 'road',
			elementType: 'geometry.stroke',
			stylers: [
				{ color: '#001b2e' },
				{ lightness: 4 },
				{ weight: '2' },
			],
		},
		{
			featureType: 'water',
			elementType: 'all',
			stylers: [{ lightness: -10 }],
		},
	];

	return (
		<div style={{ height }} className={styles.map__container}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: 'AIzaSyCog7RsE7QqPGoGhJePgBaaXqNbuO8fDAE' }}
				defaultCenter={[lat, lng]}
				defaultZoom={6.85}
				center={[lat, lng]}
				options={mapOptions}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map }) => {
					mapRef.current = map;
				}}
			>
				{markers
					.filter(marker => {
						const markerLat = +marker.lat;
						const markerLng = +marker.lng;
						return markerLat >= 36.0 && markerLat <= 47.0 && markerLng >= 6.0 && markerLng <= 18.0;
					})
					.map((marker, idx) => (
						<Marker
							key={idx}
							lat={+marker.lat}
							lng={+marker.lng}
							markerColor={marker.color}
							isActive={marker.isActive}
							name={marker.name}
							onClick={() => navigate(`${ROUTES.ESTABLISHMENTS}/${marker.id}`)}
						/>
					))}
			</GoogleMapReact>
			<label className={clx(styles.switch__wrapper, {
				[styles.switch__checked]: isDarkMode,
				[styles.switch__mobile]: isMobile,
			})}>
				<input
					id="isDarkMode"
					type="checkbox"
					checked={isDarkMode}
					className={styles.switch__field}
					onChange={() => setIsDarkMode(prev => !prev)}
				/>
				<span className={styles.switch__button}>
					{isDarkMode ? <MoonIcon /> : <SunIcon />}
				</span>
			</label>
		</div>
	);
};

export default GoogleMapContainer;
