import clx from 'classnames';

import BellIcon from '../../../../assets/svg/bell.svg';
import PrimaryButton from '../../../Common/Buttons/PrimaryButton';
import AuthPageWrapper from '../AuthPageWrapper';
import styles from './SuccessPage.module.scss';

const SuccessPage = ({
	isMobile, action
}) => (
	<AuthPageWrapper isMobile={isMobile}>
		<div className={clx(styles['success-page__wrapper'], { [styles['success-page__mobile']]: isMobile })}>
			<div className={styles['success__notification-card']}>
				<img src={BellIcon} alt='envelope' />
				<h3>Controlla{!isMobile && <br />} la tua posta</h3>
				<p>Per attivare il tuo account</p>
			</div>
			<PrimaryButton
				width='100%'
				text='Prosegui'
				onClick={action}
			/>
		</div>
	</AuthPageWrapper>
);

export default SuccessPage;
