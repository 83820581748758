import { useQuery } from '@apollo/client';
import clx from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { GET_ME } from '../../../../../api/queries'; // Ensure this path is correct
import HeartFillImage from '../../../../../assets/svg/heart.svg';
import HeartImage from '../../../../../assets/svg/heart-outlined.svg';
import { ROUTES } from '../../../../../routes/constants';
import PrimaryButton from '../../../../Common/Buttons/PrimaryButton';
import GoogleMapContainer from '../../../../Common/GoogleMapContainer';
import SkeletonLine from '../../../../Common/SkeletonLine';
import { ESTABLISHMENT_ICON_COLORS } from '../../constants';
import styles from './Establishments.module.scss';

const Establishments = () => {
	const {
		isLoading,
		myref,
		establishmentsList = [], // Default to an empty array
		activeCategory,
		isMobile,
		removeFromFavourite,
		addToFavourite,
		favorites,
		cityCoord,
	} = useOutletContext();

	const navigate = useNavigate();
	const [pinColor, setPinColor] = useState('#7a42ff');
	const [activeEstablishmentCoord, setActiveEstablishmentCoord] = useState({
		establishmentId: '',
		lat: '0',
		lng: '0',
	});

	// Fetch user data
	const {
		data: userData, loading: userLoading, error: userError
	} = useQuery(GET_ME);

	useEffect(() => {
		if (userError) {
			console.error('Error fetching user data:', userError);
		}
	}, [userError]);

	// Extract user disabilities and facilities
	const userDisabilities = userData?.me?.disabilities || [];
	const userFacilities = userData?.me?.facilities || [];

	// Function to check if an establishment matches user preferences
	const matchesUserPreferences = establishment => {
		const establishmentDisabilities = establishment?.disabilities || [];
		const establishmentFacilities = establishment?.facilities || [];

		const hasMatchingDisabilities = userDisabilities.some(userDisability =>
			establishmentDisabilities.some(
				establishmentDisability => userDisability.id === establishmentDisability.id
			)
		);

		const hasMatchingFacilities = userFacilities.some(userFacility =>
			establishmentFacilities.some(
				establishmentFacility => userFacility.id === establishmentFacility.id
			)
		);

		// Log establishment and match results
		console.log('Establishment:', establishment.name);
		console.log('Has Matching Disabilities:', hasMatchingDisabilities);
		console.log('Has Matching Facilities:', hasMatchingFacilities);

		return hasMatchingDisabilities || hasMatchingFacilities;
	};

	const handleClick = cat => {
		if (myref.current[cat.typologyId]) {
			myref.current[cat.typologyId].click();
		}
	};

	useEffect(() => {
		if (activeCategory) {
			const iconColor = ESTABLISHMENT_ICON_COLORS.find(
				item => +item.id === +activeCategory
			)?.color;
			setPinColor(iconColor || '#7a42ff'); // Fallback color
		}
	}, [activeCategory]);

	useEffect(() => {
		if (establishmentsList.length > 0) {
			setActiveEstablishmentCoord({
				establishmentId: establishmentsList[0]?.id || '',
				lat: cityCoord.lat || establishmentsList[0]?.address?.lat || '0',
				lng: cityCoord.lng || establishmentsList[0]?.address?.lng || '0',
			});
		}
	}, [establishmentsList, cityCoord]);

	// Determine which establishments to display based on user login state
	const establishmentsToDisplay = userData && userData.me ?
		establishmentsList.filter(matchesUserPreferences) :
		establishmentsList;

	// Log the filtered establishments
	useEffect(() => {
		console.log('Filtered establishments:', establishmentsToDisplay);
	}, [establishmentsToDisplay]);

	const markers = establishmentsToDisplay.map(establishment => ({
		id: +establishment.id,
		name: establishment.name,
		color: pinColor,
		lat: establishment.address.lat,
		lng: establishment.address.lng,
		isActive: +activeEstablishmentCoord.establishmentId === +establishment.id,
	}));

	return (
		<>
			{!isLoading && !userLoading ? (
				<div
					className={clx(styles['results__establishments'], {
						[styles['results-establishments__mobile']]: isMobile,
						[styles['results-establishments__favorites']]: favorites,
					})}
				>
					<div className={styles['establishments__list']}>
						<p className={styles.results__total}>
							{establishmentsToDisplay.length} {favorites ? 'Preferiti' : 'locali trovati'}
						</p>
						<ul>
							{establishmentsToDisplay.length > 0 ?
								(
									establishmentsToDisplay.map(establishment => {
										const {
											buildingNumber,
											city,
											lat,
											lng,
											street,
										} = establishment.address;

										return (
											<li
												className={styles.list__item}
												key={establishment.id}
												onMouseEnter={() =>
													setActiveEstablishmentCoord({
														establishmentId: establishment.id,
														lat,
														lng,
													})
												}
											>
												<img
													src={
														establishment.cover?.url || establishment.gallery?.[0]?.url
													}
													alt="establishment"
													onClick={() => isMobile && navigate(ROUTES.ESTABLISHMENTS)}
												/>
												<div className={styles.item__info}>
													<header>
														<p className={styles.item__categories}>
															{establishment.typologiesAndCategories.map((category, idx) => (
																<span
																	onClick={() => handleClick(category)}
																	key={idx}
																>
																	{category.name}
																</span>
															))}
														</p>
														<img
															src={
																establishment.isFavorite ?
																	HeartFillImage :
																	HeartImage
															}
															className={styles.btn__favourite}
															alt="favourite"
															onClick={async () => {
																if (establishment.isFavorite) {
																	await removeFromFavourite({
																		variables: {
																			establishmentId: +establishment.id,
																		},
																	});
																} else {
																	await addToFavourite({
																		variables: {
																			establishmentId: +establishment.id,
																		},
																	});
																}
															}}
														/>
													</header>
													<p className={styles.item__name}>{establishment.name}</p>
													<p className={styles.item__address}>
														<span className={styles.address__details}>
															{city}, {street}, {buildingNumber}
														</span>
													&nbsp;&nbsp;&#8226;&nbsp; &nbsp;
														<span
															className={styles.link__map}
															onClick={() =>
																setActiveEstablishmentCoord({
																	establishmentId: establishment.id,
																	lat,
																	lng,
																})
															}
														>
														Mostra sulla mappa
														</span>
													</p>
													{!isMobile && (
														<footer>
															<PrimaryButton
																height="44px"
																fontSize="15px"
																padding="12px 24px"
																width="auto"
																text="Vedi dettaglio"
																onClick={() =>
																	navigate(`${ROUTES.ESTABLISHMENTS}/${establishment.id}`)
																}
															/>
														</footer>
													)}
												</div>
											</li>
										);
									})
								) :
								(
									<p>No establishments match your preferences.</p>
								)}
						</ul>
					</div>
					<div className={styles.map__wrapper}>
						<GoogleMapContainer
							isMobile={isMobile}
							mapKey={activeCategory}
							lat={activeEstablishmentCoord.lat}
							lng={activeEstablishmentCoord.lng}
							setActiveCoord={setActiveEstablishmentCoord}
							markers={markers} // Use the markers array created above
						/>
					</div>
				</div>
			) : (
				<div className={styles.loader__wrapper}>
					<SkeletonLine width={isMobile ? '100%' : '150px'} />
				</div>
			)}
		</>
	);
};

export default Establishments;
