export const ESTABLISHMENT_ICON_COLORS = [
	{
		id: 1,
		color: '#FFA900'
	},
	{
		id: 2,
		color: '#48C0FC'
	},
	{
		id: 3,
		color: '#FC5E20'
	},
	{
		id: 4,
		color: '#80B99D'
	},
	{
		id: 5,
		color: '#A391FF'
	},
	{
		id: 10,
		color: '#D60078'
	},
	{
		id: 11,
		color: '#EF253D'
	},
	{
		id: 55,
		color: '#7A42FF'
	},
	{
		id: 57,
		color: '#7A42FF'
	},
	{
		id: 58,
		color: '#7A42FF'
	},
	{
		id: 59,
		color: '#7A42FF'
	},
	{
		id: 60,
		color: '#7A42FF'
	},
	{
		id: 61,
		color: '#7A42FF'
	},
	{
		id: 62,
		color: '#7A42FF'
	},
	{
		id: 63,
		color: '#7A42FF'
	},
	{
		id: 64,
		color: '#7A42FF'
	},
	{
		id: 65,
		color: '#7A42FF'
	},
	{
		id: 66,
		color: '#7A42FF'
	},
	{
		id: 67,
		color: '#7A42FF'
	},
	{
		id: 68,
		color: '#7A42FF'
	},
	{
		id: 69,
		color: '#7A42FF'
	},
	{
		id: 70,
		color: '#7A42FF'
	},
	{
		id: 71,
		color: '#7A42FF'
	},
	{
		id: 72,
		color: '#7A42FF'
	},
	{
		id: 72,
		color: '#7A42FF'
	},
	{
		id: 73,
		color: '#7A42FF'
	},
	{
		id: 74,
		color: '#7A42FF'
	},
	{
		id: 75,
		color: '#7A42FF'
	},
	{
		id: 76,
		color: '#7A42FF'
	},
	{
		id: 77,
		color: '#7A42FF'
	},
	{
		id: 78,
		color: '#7A42FF'
	},
	{
		id: 79,
		color: '#7A42FF'
	},
	{
		id: 80,
		color: '#7A42FF'
	},
	{
		id: 81,
		color: '#7A42FF'
	},
	{
		id: 82,
		color: '#7A42FF'
	},
	{
		id: 83,
		color: '#7A42FF'
	},
	{
		id: 84,
		color: '#7A42FF'
	},
	{
		id: 85,
		color: '#7A42FF'
	},
	{
		id: 86,
		color: '#7A42FF'
	},
	{
		id: 87,
		color: '#7A42FF'
	},
	{
		id: 88,
		color: '#7A42FF'
	},
	{
		id: 89,
		color: '#7A42FF'
	},
	{
		id: 90,
		color: '#7A42FF'
	},
	{
		id: 91,
		color: '#7A42FF'
	},
	{
		id: 92,
		color: '#7A42FF'
	},
	{
		id: 93,
		color: '#7A42FF'
	},
	{
		id: 94,
		color: '#7A42FF'
	},
	{
		id: 95,
		color: '#7A42FF'
	},
	{
		id: 96,
		color: '#7A42FF'
	},
	{
		id: 97,
		color: '#7A42FF'
	},
	{
		id: 98,
		color: '#7A42FF'
	},
	{
		id: 99,
		color: '#7A42FF'
	},
	{
		id: 100,
		color: '#7A42FF'
	},
];
