/* eslint-disable max-len */
import { useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames';
import {
	Field, FieldArray, Form, Formik
} from 'formik';
import React, { useRef, useState } from 'react';
import {
	Link, Routes, useNavigate, useOutletContext, useResolvedPath, useRoutes
} from 'react-router-dom';

import DefaultImage from '../../../../../assets/images/default.webp';
import SpinnerGif from '../../../../../assets/images/spinner.gif';
import Arrow from '../../../../../assets/svg/arrow.svg';
import Pen from '../../../../../assets/svg/pen.svg';
import { ROUTES } from '../../../../../routes/constants';
import useWindowSize from '../../../../../utils/useWindowSize';
import PrimaryButton from '../../../../Common/Buttons/PrimaryButton';
import Modal from '../../../../Common/Modal';
import PrimaryCard from '../../../../Common/PrimaryCard';
import Switch from '../../../../Common/Switch';
import TextField from '../../../../Common/TextField';
import UploadFileModal from '../../../../Common/UploadFileModal';
import { GET_DISABILITIES, GET_FACILITIES } from '../../../Home/api/queries';
import { EDIT_PROFILE, UPLOAD_PICTURE } from '../../api/mutations';
import { PROFILE_EDIT_VALIDATION_SCHEMA } from './constants';
import { CardIcon } from './containers/CardIcon';
import { ModalProfile } from './containers/ModalProfile';
import style from './Profile.module.scss';

const Profile = () => {
	const { user } = useOutletContext();
	const navigate = useNavigate();
	const [isChangeble, setIsChangeble] = useState(false);
	const [disabilitiesModal, setDisabilitiesModal] = useState(false);
	const [facilitiesModal, setFacilitiesModal] = useState(false);
	const [imageBase64, setImageBase64] = useState(null);
	const [imageModal, setImageModal] = useState(null);
	const { data: disabilitiesList } = useQuery(GET_DISABILITIES);
	const { data: faclitiesList } = useQuery(GET_FACILITIES);
	const [editProfile, { loading: loadingUser, client }] = useMutation(EDIT_PROFILE, {
		refetchQueries: ['me'],
	});

	const [uploadPicture, { loading: loadingPhoto }] = useMutation(UPLOAD_PICTURE, {
		refetchQueries: ['me'],
	});

	const { isMobile } = useWindowSize();
	return (
		<div className={classNames(style.profile, { [style['profile__mbl']]: isMobile })}>
			<Modal background="#203150" setIsModalOpen={() => setImageModal(!imageModal)} isModalOpen={imageModal}
				title="Modifica immagine" width="min(350px, 90%)">
				{!(loadingPhoto || loadingUser) ?
					(
						<UploadFileModal isMobile={isMobile} setUploadFile={setImageBase64} uploadFile={imageBase64}
							isProfileImg={true} />
					) :
					(
						<center>
							<img style={{ marginBottom: '40px' }} src={SpinnerGif} alt="default"
								width="100px" height="100px" />
						</center>
					)}

				<div
					style={{
						display: 'flex',
						width: '100%',
						gap: '20px',
					}}
				>
					<PrimaryButton
						text="salva"
						width="100%"
						onClick={() => {
							if (!imageBase64) return;
							const base64 = imageBase64.split(',')[1];
							const type = imageBase64.split(',')[0].split(':')[1].split(';')[0];
							const extension = type.split('/')[1];
							const blobValue = new Blob([Buffer.from(base64, 'base64')], { type });
							blobValue.name = 'profile_image_' + user?.id + '.' + extension;
							uploadPicture({
								variables: { picture: blobValue },
								onCompleted: values => {
									setImageModal(false);
									const {
										uploadPicture: {
											uploadPicture: [{ id }],
										},
									} = values;
									editProfile({
										variables: {
											editUserInput: {
												avatarId: id,
											},
										},
										onCompleted: () => {
											setImageBase64(null);
										},
									});
								},
							});
						}}
					/>
					<PrimaryButton
						text="Annula"
						width="100%"
						onClick={() => {
							setImageBase64(null);
							setImageModal(false);
						}}
						background="linear-gradient(232.71deg, rgba(14, 237, 255, 0.2) -23.85%, rgba(171, 116, 249, 0.2) 109.37%)"
					/>
				</div>
			</Modal>

			<div className={style.profile__content}>
				<div className={style['profile__back']}>
					<img src={Arrow} alt="arrow" role="button"
						onClick={() => {}} />
					<h1>Informazioni Personale</h1>
				</div>
				<div onClick={() => setImageModal(true)} className={style['profile__image']} role="button">
					<img src={user?.avatar?.url || DefaultImage} width="88" alt="profile image" />
					<p role="button">Cambia Immagine</p>
				</div>

				<div className={style['profile__form__box']}>
					<div className={style['profile__form__edit']} role="button" onClick={() => setIsChangeble(true)}>
						<span>Modifica dati &nbsp;</span>
						<img src={Pen} alt="pen" />
					</div>
					<Formik
						initialValues={{
							nome: user?.firstName + ' ' + user?.lastName,
							email: user?.email,
							dateBirth: user?.dateBirth,
							city: user?.address?.city,
							disabilityIds: [user?.disabilities[0]?.id],
							facilityIds: [user?.facilities[0]?.id],
							accompanied: user?.accompanied,
						}}
						onSubmit={values =>
							editProfile({
								variables: {
									editUserInput: {
										firstName: values.nome.split(' ')[0],
										lastName: values.nome.split(' ')[1],
										email: values.email,
										dateBirth: values.dateBirth,
										disabilityIds: values.disabilityIds,
										facilityIds: values.facilityIds,
										accompanied: values.accompanied,
									},
								},
								onCompleted: () => {
									setIsChangeble(false);
								},
							})
						}
						validationSchema={PROFILE_EDIT_VALIDATION_SCHEMA}
					>
						{({
							values, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched
						}) => (
							<>
								<Form>
									<Field disabled={!isChangeble} name="nome" id="nome"
										placeholder="Nome Cognome" component={TextField} />
									<Field disabled={!isChangeble} name="email" id="email"
										placeholder="Email" component={TextField} />
									<Field disabled={!isChangeble} name="dateBirth" id="dateBirth"
										placeholder="Età" type="date" component={TextField} />
									<Field disabled={!isChangeble} name="city" id="city"
										placeholder="Cità" component={TextField} />
									<div className={style['profile__form__select']}>
										<PrimaryCard
											text={disabilitiesList?.disabilities.find(({ id }) => +values?.disabilityIds[0] === +id)?.name}
											iconSrc={disabilitiesList?.disabilities.find(({ id }) => +values?.disabilityIds[0] === +id)?.url || ''}
											isActive={false}
											action={() => {
												setDisabilitiesModal(!disabilitiesModal);
											}}
											height="150px"
										/>

										<PrimaryCard
											text={faclitiesList?.facilities.find(({ id }) => values?.facilityIds[0] == id)?.name}
											iconSrc={faclitiesList?.facilities.find(({ id }) => values?.facilityIds[0] == id)?.url}
											isActive={false}
											action={() => {
												setFacilitiesModal(!facilitiesModal);
											}}
											height="150px"
										/>

										<FieldArray
											name="disabilityIds"
											render={arrayHelpers => (
												<ModalProfile isModalOpen={disabilitiesModal && isChangeble} setIsModalOpen={() => setDisabilitiesModal(!disabilitiesModal)} title="Il tuo caso">
													<div className={style.disabilities__list}>
														{disabilitiesList?.disabilities?.map(disability => (
															<PrimaryCard
																key={disability?.id}
																text={disability?.name}
																iconSrc={disability?.url}
																isActive={values?.disabilityIds?.includes(+disability?.id)}
																action={() => {
																	if (values?.disabilityIds?.includes(+disability?.id)) {
																		setFieldValue('disabilityIds', []);
																	} else {
																		setFieldValue('disabilityIds', [+disability?.id]);
																		setDisabilitiesModal(false);
																	}
																	setFieldTouched('disabilityIds', true);
																}}
																height={isMobile ? '100px' : '150px'}
															/>
														))}
													</div>
												</ModalProfile>
											)}
										/>

										<FieldArray
											name="facilityIds"
											render={arrayHelpers => (
												<ModalProfile isModalOpen={facilitiesModal && isChangeble} setIsModalOpen={() => setFacilitiesModal(!facilitiesModal)} title="Il tuo caso">
													<div className={style.facilities__list}>
														{faclitiesList?.facilities?.map(facility => (
															<PrimaryCard
																key={facility?.id}
																text={facility?.name}
																iconSrc={facility?.url}
																isActive={values?.facilityIds?.includes(+facility?.id)}
																action={() => {
																	if (values?.facilityIds?.includes(+facility?.id)) {
																		setFieldValue('facilityIds', []);
																	} else {
																		setFieldValue('facilityIds', [+facility?.id]);
																		setFacilitiesModal(false);
																	}
																	setFieldTouched('facilityIds', true);
																}}
																height={isMobile ? '100px' : '150px'}
															/>
														))}
													</div>
												</ModalProfile>
											)}
										/>
									</div>
									<div className={style['row']}>
										<h3>Accompagnato? </h3>
										<Field disabled={!isChangeble} name="accompanied" id="accompanied"
											component={Switch} />
									</div>
									{isChangeble && (
										<footer className={style['profile__form__buttons']}>
											<PrimaryButton
												text={!isMobile ? 'Salva' : 'Salva modifiche'}
												width="100%"
												onClick={() => {
													handleSubmit();
													setIsChangeble(false);
												}}
											/>
											<PrimaryButton
												text="Anulla"
												width="100%"
												background="linear-gradient(232.71deg, rgba(14, 237, 255, 0.2) -23.85%, rgba(171, 116, 249, 0.2) 109.37%)"
												onClick={() => setIsChangeble(false)}
											/>
										</footer>
									)}
								</Form>
							</>
						)}
					</Formik>
					{!isChangeble && (
						<center>
							<Link
								onClick={() => {
									client.resetStore();
									localStorage.removeItem('jwtToken_world4all');
									navigate(ROUTES.HOME);
								}}
							>
								Esci
							</Link>
						</center>
					)}
				</div>
			</div>
		</div>
	);
};
export default Profile;
