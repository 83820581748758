import clx from 'classnames';

import ArrowIcon from '../../../../../../../assets/svg/arrow.svg';
import CrossIcon from '../../../../../../../assets/svg/cross.svg';
import IconButton from '../../../../../../Common/Buttons/IconButton';
import PrimaryButton from '../../../../../../Common/Buttons/PrimaryButton';
import Carousel from '../../../../../../Common/Carousel';
import styles from './EstablishmentGallery.module.scss';

const EstablishmentGallery = ({
	isMobile, setIsCarouselOpen, gallery
}) => (
	<div className={clx(
		styles['gallery-carousel__wrapper'],
		{ [styles['gallery-carousel__mobile']]: isMobile })}>
		<header>
			{isMobile ?
				<IconButton
					iconSrc={ArrowIcon}
					width='30px'
					height='auto'
					onClick={() => setIsCarouselOpen(false)}
				/> :
				<PrimaryButton
					text='Chiudi'
					leftIconSrc={CrossIcon}
					background='transparent'
					height='auto'
					width='auto'
					padding='0'
					fontSize='18px'
					onClick={() => setIsCarouselOpen(false)}
				/>
			}
		</header>
		<Carousel
			noNav={false}
			showPagination={true}
			spaceBetween={isMobile ? 0 : 45}
			isMobile={isMobile}
			slidesPerView={1}
			slidesPerViewMobile={1}
			slides={gallery?.map(photo => (
				<div key={photo?.id}
					className={clx(
						styles['photo-slide']
					)}>
					<img src={photo?.url} alt='establishment' />
					{photo?.title && <p className={styles.photo__title}>{photo?.title}</p>}
					{photo?.description && <p className={styles.photo__desc}>{photo?.description}</p>}
				</div>
			))}
		/>
	</div>
);

export default EstablishmentGallery;
