import { gql } from '@apollo/client';

export const GET_ALL_TROVERA = gql`
query GetAllTrovera($filter: Filter) {
	GetAllTrovera(Filter: $filter) {
		trovera {
			id
			name
		}
		troveraToEstablishment {
			id
			idEstablishment
			idTrovera
		}
	}
}
`;
