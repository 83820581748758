import { gql } from '@apollo/client';

export const UPLOAD_PICTURE = gql`
	mutation EditUser($picture: [Upload!]!) {
		uploadPicture(picture: $picture) {
			uploadPicture {
				url
				title
				description
				id
			}
		}
	}
`;
export const EDIT_PROFILE = gql`
	mutation Mutation($editUserInput: EditUserInput!) {
		editUser(editUserInput: $editUserInput) {
			firstName
			lastName
			email
			dateBirth
			accompanied
			disabilities {
				id
			}
			facilities {
				id
			}
		}
	}
`;
