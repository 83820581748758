export const ROUTES = {
	SIGN_IN: '/login',
	SIGN_UP: '/registration',
	FORGOT_PASSWORD: '/forgotPassword',
	RESET_PASSWORD: '/resetPassword/:token',
	HOME: '/',
	ESTABLISHMENTS: '/establishments-list',
	FAVORITES: '/favorites',
	PROFILE: '/profile',

};
