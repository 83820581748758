import { gql } from '@apollo/client';

export const GET_ESTABLISHMENTS = gql`
query establishmentsList ($establishmentListInput: EstablishmentListInput!) {
    establishmentsList (establishmentListInput: $establishmentListInput) {
			id
			name
			phone
			email
			website
			description
			details {
					id
					code
					description
					title
					image {
							id
							url
					}
			}
			address {
					id
					country
					city
					street
					buildingNumber
					apartment
					lat
					lng
			}
			typologiesAndCategories {
					typologyId
					name
					category {
							id
							name
							isDisabled
					}
					isDisabled
			}
			disabilities {
					id
					name
					isDisabled
			}
			facilities {
					id
					name
					isDisabled
			}
			isFavorite
			cover {
					id
					url
			}
			gallery {
					id
					url
			}
			createdAt
			updatedAt
	}
}
`;

export const GET_ESTABLISHMENT = gql`
query establishment ($establishmentInput: EstablishmentInput!) {
    establishment (establishmentInput: $establishmentInput) {
        id
        name
        phone
        email
        website
        description
        details {
            id
            code
            description
            title
            image {
                id
                url
            }
        }
        address {
            id
            country
            city
            street
            buildingNumber
            apartment
            lat
            lng
        }
        typologiesAndCategories {
            typologyId
            name
            category {
                id
                name
                isDisabled
            }
            isDisabled
        }
        disabilities {
            id
            name
            isDisabled
        }
        facilities {
            id
            name
            isDisabled
        }
        isFavorite
        cover {
            id
            url
        }
        gallery {
            id
            url
            title
            description
        }
        createdAt
        updatedAt
				enabled
    }
}
`;

export const GET_CATEGORIES = gql`
query establishmentCategories {
	establishmentCategories {
			id
			name
			url
			isDisabled
	}
}
`;

export const GET_TYPOLOGIES_BY_CATEGORY = gql`
query establishmentTypologies ($getTypologyInput: GetTypologyInput) {
	establishmentTypologies (getTypologyInput: $getTypologyInput){
			id
			name
			categoryId
			isDisabled
	}
}
`;

export const GET_FACILITIES = gql`
query facilities {
    facilities {
        id
        name
				url
        isDisabled
    }
}
`;

export const GET_DISABILITIES = gql`
query disabilities {
    disabilities {
        id
        name
				url
        isDisabled
    }
}
`;

export const GET_TAGS = gql`
query GetAllTagsAndFields($filter: filter!) {
  GetAllTagsAndFields(Filter: $filter) {
    tags {
      name
      idSezione
      idCategoria
      idTag
    }
  }
}
`;
