import classNames from 'classnames';
import { useEffect, useState } from 'react';

import useWindowSize from '../../../../../../../utils/useWindowSize';
import PrimaryButton from '../../../../../../Common/Buttons/PrimaryButton';
import Modal from '../../../../../../Common/Modal';
import { CardIcon } from '../CardIcon';
import styles from './ModalProfile.module.scss';

export const ModalProfile = ({
	children, isModalOpen, title, setIsModalOpen, width
}) => {
	const { isMobile } = useWindowSize();
	return (
		<Modal
			width={width || (isMobile ? '100%' : 'min(1200px, 80%)')}
			height={isMobile ? '100%' : 'auto'}
			padding={isMobile ? '20px' : '30px'}
			background="transparent"
			isModalOpen={isModalOpen}
			title={title}
			setIsModalOpen={setIsModalOpen}
			className={styles['modal']}
			classNameWrapper={classNames(styles['modal__wrapper'], { [styles['modal__wrapper__mbl']]: isMobile })}
			showCloseBtn={true}
		>
			{children}
		</Modal>
	);
};
