// To save the table state with filters and search

export const appendFilterListToColumns = (columns, filterList) => {
	if (filterList) {
		filterList.forEach((filter, index) => {
			if (!columns[index]?.options) {
				let filters = {
					filter: true,
					filterList: []
				};
				columns[index].options = filters;
			}
			if (filter && (filter.length > 0)) {
				columns[index].options.filterList = filter;
			}
		});
	}
	return columns;
};

export const clearLocalStorageByTableKey = tableKey => {
	Object.entries(localStorage).map(([key]) => {
		const KEY = key.split('/').pop();
		if (KEY === tableKey) localStorage.removeItem(key);
		return key;
	});
};

export const paginate = (array, page_size, page_number) =>
	array.slice((page_number - 1) * page_size, page_number * page_size);


export const getDateWithTime = dateStr => {
	let formattedDate = new Date(dateStr).toLocaleDateString('en-Gb', {
		'day': '2-digit',
		'month': 'long',
		'year': 'numeric',
		'hour': 'numeric',
		'minute': 'numeric',
		'hour12': true
	});

	if (formattedDate == 'Invalid Date') {
		return 'No date';
	} else {
		formattedDate = formattedDate.split(',');
		formattedDate[1] = formattedDate[1].toUpperCase();

		return formattedDate?.join().replace(',', ' –');
	}
};

export const formatEuro = digit =>
	(+digit)?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€';


export const fileReaderToBase64 = async file => {
	const prom = file => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
	const uploadedFile = await prom(file[0]);
	return uploadedFile;
};
