import clx from 'classnames';
import { Link } from 'react-router-dom';

import FacebookIcon from '../../../assets/svg/facebook.svg';
import LikeIcon from '../../../assets/svg/heart.svg';
import InstagramIcon from '../../../assets/svg/instagram.svg';
import UserIcon from '../../../assets/svg/user.svg';
import LogoLink from '../LogoLink';
import SkeletonLine from '../SkeletonLine';
import styles from './NavBar.module.scss';

const NavBar = ({
	user, isAuthenticated, isLoading, pathname, isMobile, routes
}) => (
	<header className={clx(styles.navbar, { [styles.navbar__mobile]: isMobile })}>
		<div
			className={clx(styles.navbar__menu, {
				[styles.navbar__centered]: !isAuthenticated,
				[styles.navbar__full]: pathname !== routes.HOME,
			})}
		>
			<LogoLink height={isMobile ? '36px' : '58px'} />
			{!isMobile ? (
				<div className={styles.menu__wrapper}>
					<ul className={styles.nav__links}>
						{isLoading ? (
							<SkeletonLine />
						) : isAuthenticated ? (
							<>
								<li>
									<Link
										to={pathname === routes.FAVORITES ? '/' : routes.FAVORITES}
										className={clx(
											styles.link
											// { [styles.link__active]: pathname === routes.PROFILE }
										)}
									>
										<img src={LikeIcon} alt="favourites" className={styles.link__icon} />
										Preferiti
									</Link>
								</li>
								<li>
									<Link to={routes.PROFILE} className={clx(styles.link, styles.link__profile)}>
										<img src={user?.avatar?.url || UserIcon} alt="avatar" className={styles.avatar} />
										{user?.firstName || '---'} {user?.lastName || ''}
									</Link>
								</li>
							</>
						) : (
							<>
								<li>
									<Link to={routes.SIGN_IN} className={styles.login__link}>
										Accedi
									</Link>
								</li>
								<li>
									<Link to={routes.SIGN_UP} className={styles.signup__link}>
										Registrati
									</Link>
								</li>
							</>
						)}
					</ul>
					<ul className={styles.nav__socials}>
						<li>
							<a href="https://www.instagram.com/world4all.fun" target="_blank" rel="noopener noreferrer"
								className={styles.social__link}>
								<img src={InstagramIcon} alt="instagram" className={styles.social__icon} />
							</a>
						</li>
						<li>
							<a href="https://www.facebook.com/world4all.fun" target="_blank" rel="noopener noreferrer"
								className={styles.social__link}>
								<img src={FacebookIcon} alt="facebook" className={styles.social__icon} />
							</a>
						</li>


					</ul>
				</div>
			) : isAuthenticated ?
				(
					<ul className={styles.nav__links}>
						<li>
							<Link to={'/'} className={clx(styles.link)}>
								<img src={LikeIcon} alt="favourites" className={styles.link__icon} />
							</Link>
						</li>
						<li>
							<Link to={routes.PROFILE} className={clx(styles.link, { [styles.link__active]: pathname === routes.PROFILE })}>
								<img src={user?.avatar || UserIcon} alt="avatar" className={styles.avatar} />
							</Link>
						</li>
					</ul>
				) :
				null}
		</div>
	</header>
);

export default NavBar;
