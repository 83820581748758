import {
	ApolloClient, InMemoryCache, makeVar
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';

export const isAuthenticated = makeVar(false);

const TOKEN_KEY = 'authToken';

export const getApolloClient = () => {
	const httpLink = createUploadLink({
		uri: 'https://api.world4all.fun/graphql',
		// uri: 'http://localhost:5001/graphql'
	});

	const authLink = setContext((_, { headers }) => {
		const token = localStorage.getItem('jwtToken_world4all');

		if (token) {
			isAuthenticated(true);
		} else {
			isAuthenticated(false);
		}

		return {
			headers: {
				...headers,
				...(!!token && { authorization: token || '' }),
			},
		};
	});

	// Function to remove token from localStorage and set isAuthenticated to false
	const removeToken = () => {
		localStorage.removeItem('jwtToken_world4all');
		isAuthenticated(false);
	};

	// Set timeout for token removal after 2 minute of inactivity
	let timeoutId;
	const inactivityTimeout = 120000; // 2 minute in milliseconds
	const resetTimeout = () => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(removeToken, inactivityTimeout);
	};

	// Reset timeout when there is user activity
	const events = ['mousemove', 'mousedown', 'keypress', 'scroll', 'touchstart'];
	events.forEach(event => {
		window.addEventListener(event, resetTimeout);
	});

	// Remove token when the tab is closed
	window.addEventListener('beforeunload', removeToken);

	// Initialize timeout on page load
	resetTimeout();

	return new ApolloClient({
		link: authLink.concat(httpLink),
		cache: new InMemoryCache(),
		introspection: false,
	});
};
