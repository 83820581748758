import { ApolloProvider } from '@apollo/client';
import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { getApolloClient } from '../../api/apolloClient';
import Spinner from '../Common/Spinner';

const AppProviders = ({ children }) => {
	const [apolloClient, setApolloClient] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const client = getApolloClient();

		if (client) {
			setApolloClient(client);
			setLoading(false);
		}
	}, []);

	if (loading) return <Spinner />;

	return <ApolloProvider client={apolloClient}>
		<BrowserRouter basename='/'>
			{children}
		</BrowserRouter>
	</ApolloProvider>;
};


export default AppProviders;
