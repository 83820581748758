import clx from 'classnames';

import styles from './IconButton.module.scss';

const IconButton = ({
	height = '30px',
	width = '30px',
	padding = '5px',
	borderRadius = '5px',
	backgroundColor = 'transparent',
	iconSrc,
	onClick,
	type
}) => (
	<button
		type={type}
		style={{
			height,
			width,
			borderRadius,
			padding,
			backgroundColor
		}}
		className={clx(
			styles.icon__btn
		)}
		onClick={onClick}
	>
		<img className={styles['btn__icon']} src={iconSrc} alt='icon' />
	</button>
);

export default IconButton;
