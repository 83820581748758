import clx from 'classnames';

import QuestionMarkIcon from '../../../assets/svg/question-mark.svg';
import styles from './PrimaryCard.module.scss';

const PrimaryCard = ({
	iconSrc, text, isActive = false, action, height = '141px', style = {}
}) => (
	<div
		className={clx(styles['primary-card'], { [styles.card__active]: isActive })}
		onClick={action}
		style={{
			height,
			...style,
		}}
	>
		<img
			src={iconSrc}
			alt="icon"
			onError={({ currentTarget }) => {
				currentTarget.onerror = null;
				currentTarget.src = QuestionMarkIcon;
			}}
		/>
		<p>{text}</p>
	</div>
);

export default PrimaryCard;
