import axios from 'axios';

const token = localStorage.getItem('jwtToken');

export const axiosClient = axios.create({
	baseURL: 'https://api.world4all.fun/graphql',
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
		authorization: token || '',
	},
});
