import 'react-datepicker/dist/react-datepicker.css';

import { useMutation, useQuery } from '@apollo/client';
import clx from 'classnames';
import { it } from 'date-fns/locale';
import {
	Field, FieldArray, Form, Formik
} from 'formik';
import React, {
	createRef, useEffect, useRef, useState
} from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Link, useNavigate } from 'react-router-dom';

import ArrowIcon from '../../../../assets/svg/arrow.svg';
import LogoImg from '../../../../assets/svg/logo.svg';
import { ROUTES } from '../../../../routes/constants';
import useWindowSize from '../../../../utils/useWindowSize';
import PrimaryCard from '../../../Common/PrimaryCard';
import Switch from '../../../Common/Switch';
import TextField from '../../../Common/TextField';
import { GET_DISABILITIES, GET_FACILITIES } from '../../../pages/Home/api/queries';
import { SIGN_UP } from '../../api/mutations';
import { SIGNUP_INITIAL_VALUES, SIGNUP_VALIDATION_SCHEMA } from '../../constants';
import SuccessPage from '../SuccessPage';
import SubmitButton from './containers/SubmitButton';
import styles from './SignUp.module.scss';

registerLocale('it', it);

const SignUp = () => {
	const navigate = useNavigate();
	const [signUp, { loading }] = useMutation(SIGN_UP);
	const { data: disabilitiesList } = useQuery(GET_DISABILITIES);
	const { data: faclitiesList } = useQuery(GET_FACILITIES);

	const registrationFormRef = createRef();
	const topPageRef = useRef();
	const { isMobile } = useWindowSize();
	const [isRegistrationSuccess, setIsRegistrationSuccess] = useState(false);
	const [activeStep, setActiveStep] = useState(1);

	useEffect(() => {
		topPageRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
	}, [activeStep]);

	const stepBack = () => {
		if (isMobile) {
			if (activeStep === 1) {
				navigate(-1);
			} else {
				setActiveStep(s => s - 1);
			}
		}
	};

	return (
		<div className={styles.signup__wrapper}>
			{isRegistrationSuccess ?
				(
					<SuccessPage isMobile={isMobile} action={() => navigate(ROUTES.SIGN_IN)} />
				) :
				(
					<Formik
						innerRef={registrationFormRef}
						initialValues={SIGNUP_INITIAL_VALUES}
						validationSchema={SIGNUP_VALIDATION_SCHEMA}
						onSubmit={async values => {
							await signUp({
								variables: {
									signUpInput: {
										firstName: values?.firstName,
										lastName: 'lastName',
										phone: '1234568',
										email: values?.email,
										password: values?.password,
										dateBirth: new Date(values?.dateBirth).toLocaleDateString('en-CA'),
										accompanied: values?.accompanied,
										address: {
											country: 'country',
											city: values?.address,
											street: 'street',
											buildingNumber: 'buildingNumber',
											apartment: 'apartment',
										},
										disabilityIds: values?.disabilityIds,
										facilityIds: values?.facilityIds,
									},
								},
								onCompleted: data => {
									setIsRegistrationSuccess(true);
								},
							});
						}}
					>
						{({
							values, errors, touched, setFieldTouched, setFieldValue
						}) => (
							<Form className={clx(styles['signup__form'], { [styles['signup-form__mobile']]: isMobile })} ref={topPageRef}>
								<header>
									{isMobile && (
										<Link to={ROUTES.HOME} className={styles.logo__link}>
											<img src={LogoImg} alt="logo" className={styles.logo} />
										</Link>
									)}
									{activeStep === 1 || activeStep === 2 ?
										(
											<>
												<h1 onClick={stepBack}>
													{isMobile && <img src={ArrowIcon} alt="arrow" />}
											Registrazione
												</h1>
												<p>Indica qual è la tua condizione ad oggi, che sia momentanea o permanente.</p>
											</>
										) :
										activeStep === 3 ?
											(
												<h2 className={styles.title} onClick={stepBack}>
													{isMobile && <img src={ArrowIcon} alt="arrow" />}
										Controlla che sia tutto corretto e crea il tuo profilo.
													<br />
										Potrai modificare i dati quando vorrai.
												</h2>
											) :
											activeStep === 4 ?
												(
													<>
														<h1>Completa il tuo profilo</h1>
														<p>Aggiungi queste poche informazioni per usare World4all in tutte le sue funzionalità.</p>
													</>
												) :
												null}
								</header>
								{!isMobile && (activeStep === 1 || activeStep === 2) && (
									<div className={styles.dots}>
										{[...Array(2).keys()].map(item => (
											<span key={item} className={clx(styles.dot, { [styles.dot__active]: +activeStep === +item + 1 || +activeStep > +item + 1 })} />
										))}
									</div>
								)}
								<div className={clx({ [styles.form__body]: activeStep === 4 })}>
									{activeStep === 1 ?
										(
											<>
												<FieldArray
													name="disabilityIds"
													render={arrayHelpers => (
														<div className={styles.disabilities__list}>
															{disabilitiesList?.disabilities?.map(disability => (
																<PrimaryCard
																	key={disability?.id}
																	text={disability?.name}
																	iconSrc={disability?.url}
																	isActive={values?.disabilityIds?.includes(+disability?.id)}
																	action={() => {
																		if (values?.disabilityIds?.includes(+disability?.id)) {
																			setFieldValue('disabilityIds', []);
																		} else {
																			setFieldValue('disabilityIds', [+disability?.id]);
																		}
																		setFieldTouched('disabilityIds', true);
																	}}
																	height="auto"
																/>
															))}
														</div>
													)}
												/>
												{touched['disabilityIds'] && errors['disabilityIds'] && <div className={styles.errorMsg}>{errors['disabilityIds']}</div>}
											</>
										) :
										activeStep === 2 ?
											(
												<>
													<FieldArray
														name="facilityIds"
														render={arrayHelpers => (
															<div className={styles.facilities__list}>
																{faclitiesList?.facilities?.map(facility => (
																	<PrimaryCard
																		key={facility?.id}
																		text={facility?.name}
																		iconSrc={facility?.url}
																		isActive={values?.facilityIds?.includes(+facility?.id)}
																		action={() => {
																			if (values?.facilityIds?.includes(+facility?.id)) {
																				setFieldValue('facilityIds', []);
																			} else {
																				setFieldValue('facilityIds', [+facility?.id]);
																			}
																			setFieldTouched('facilityIds', true);
																		}}
																		height="auto"
																	/>
																))}
															</div>
														)}
													/>
													{touched['facilityIds'] && errors['facilityIds'] && <div className={styles.errorMsg}>{errors['facilityIds']}</div>}
												</>
											) :
											activeStep === 3 ?
												(
													<div className={styles['check-result']}>
														<div className={styles.values__wrapper}>
															<div className={styles.result__values}>
																{disabilitiesList?.disabilities
																	?.filter(disability => values?.disabilityIds?.includes(disability?.id))
																	?.map(disability => (
																		<PrimaryCard key={disability?.id} text={disability?.name} iconSrc={disability?.url}
																			isActive={true} height="fit-content" />
																	))}
															</div>
															<div className={styles.result__values}>
																{faclitiesList?.facilities
																	?.filter(facility => values?.facilityIds?.includes(facility?.id))
																	?.map(facility => (
																		<PrimaryCard key={facility?.id} text={facility?.name} iconSrc={facility?.url}
																			isActive={true} height="fit-content" />
																	))}
															</div>
														</div>
														<div className={styles.field__accompanied}>
															<p>Viaggi accompagnato?</p>
															<div className={styles.field__switch}>
																<span>NO</span>
																<Field name="accompanied" id="accompanied" component={Switch} />
																<span>SI</span>
															</div>
														</div>
													</div>
												) :
												(
													<div className={styles['form__personal-info']}>
														<Field name="firstName" id="firstName" placeholder="Nickname"
															component={TextField} />
														<Field name="email" id="email" placeholder="Email"
															component={TextField} type="email" />
														<DatePicker
															locale="it"
															closeOnScroll={true}
															selected={values?.dateBirth}
															dateFormat="yyyy/MM/dd"
															maxDate={new Date()}
															onChange={date => setFieldValue('dateBirth', date)}
															peekNextMonth
															scrollableYearDropdown
															yearDropdownItemNumber={60}
															showMonthDropdown
															showYearDropdown
															dropdownMode="scroll"
															placeholderText="Data nascita"
														/>
														{touched['dateBirth'] && errors['dateBirth'] && <div className={styles.errorMsg}>{errors['dateBirth']}</div>}
														<Field name="address" id="address" placeholder="Luogo di provenienza"
															component={TextField} />
														<Field name="password" id="password" placeholder="Inserisci una password"
															component={TextField} type="password" showPassword={true} />
														<Field
															name="passwordConfirmation"
															id="passwordConfirmation"
															type="password"
															placeholder="Conferma password"
															component={TextField}
															showPassword={true}
														/>
													</div>
												)}
								</div>
								<SubmitButton
									isLoading={loading}
									homePagePath={ROUTES.HOME}
									navigate={navigate}
									activeStep={activeStep}
									setActiveStep={setActiveStep}
									ref={registrationFormRef}
								/>
							</Form>
						)}
					</Formik>
				)}
		</div>
	);
};

export default SignUp;
