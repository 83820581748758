import { gql } from '@apollo/client';

export const SIGN_IN = gql`
mutation signIn ($signInInput: SignInInput!) {
  signIn (signInInput: $signInInput) {
    jwtToken
  }
}
`;

export const SIGN_UP = gql`
mutation signUp ($signUpInput: SignUpInput!) {
    signUp (signUpInput: $signUpInput) {
        success
    }
}
`;

export const FORGOT_PASSWORD = gql`
mutation requestResetPassword ($requestResetPasswordInput: RequestResetPasswordInput!) {
    requestResetPassword (requestResetPasswordInput: $requestResetPasswordInput) {
        success
    }
}
`;

export const CHECK_CODE = gql`
  mutation CheckCode($code: String!) {
    checkCode(code: $code)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($resetPasswordInput: ResetPasswordInput!) {
    resetPassword(resetPasswordInput: $resetPasswordInput) {
      success
    }
  }
`;

export const CHANGE_PASSWORD = gql`
mutation ChangePassword($changePasswordInput: ChangePasswordInput!) {
  changePassword(changePasswordInput: $changePasswordInput) {
    success
  }
}
`;
