import { useLazyQuery, useMutation } from '@apollo/client';
import {
	useEffect, useMemo, useRef, useState
} from 'react';
import {
	Outlet, useLocation, useParams
} from 'react-router-dom';

import useWindowSize from '../../../utils/useWindowSize';
import {
	GET_CATEGORIES, GET_DISABILITIES, GET_ESTABLISHMENT,
	GET_ESTABLISHMENTS, GET_FACILITIES, GET_TAGS, GET_TYPOLOGIES_BY_CATEGORY
} from '../Home/api/queries';
import { ADD_TO_FAVOURITE, REMOVE_FROM_FAVOURITE } from './api/mutations';
import SearchPanel from './containers/SearchPanel';
import styles from './EstablishmentsList.module.scss';

const EstablishmentsList = ({ favorites }) => {
	const { id } = useParams();
	const location = useLocation();

	const INITIAL_ESTABLISHMENTS_FILTERS = useMemo(
		() => ({
			limit: 200,
			offset: 0,
			search: '',
			typologyIds: [],
			disabilityIds: [],
			facilityIds: [],
			order: 'desc',
		}),
		[]
	);

	const myref = useRef([]);
	const { isMobile } = useWindowSize();
	const { state } = useLocation();
	const [userLocation, setUserLocation] = useState(false);
	const [establishmentCategories, setEstablishmentCategories] = useState([]);
	const [activeCategory, setActiveGategory] = useState(false);
	const [city, setCity] = useState(location?.state?.city?.value || 0);
	const [cityCoordinates, setCityCoordinates] = useState({});
	const [facilities, setFacilities] = useState([]);
	const [disabilities, setDisabilities] = useState([]);
	const [searchTerm, setSearchTerm] = useState(''); // State for search term

	const [getCategories] = useLazyQuery(GET_CATEGORIES, {
		onCompleted: result => {
			setEstablishmentCategories(result?.establishmentCategories);
		},
	});
	const [getEstablishmentsList, { data, loading: establishmentsListLoading }] = useLazyQuery(GET_ESTABLISHMENTS);
	const [getEstablishment, { data: establishmentsDetails, loading: establishmentsDetailsLoading }] = useLazyQuery(GET_ESTABLISHMENT);
	const [getTagsList, { data: tags }] = useLazyQuery(GET_TAGS);
	const [getTypologiesList, { data: typologies }] = useLazyQuery(GET_TYPOLOGIES_BY_CATEGORY);
	const [getFacilities] = useLazyQuery(GET_FACILITIES, {
		onCompleted: result => setFacilities(result?.facilities),
	});
	const [getDisabilities] = useLazyQuery(GET_DISABILITIES, {
		onCompleted: result => setDisabilities(result?.disabilities),
	});

	const [establishmentFilters, setEstablishmentFilters] = useState(INITIAL_ESTABLISHMENTS_FILTERS);

	useEffect(() => {
		if (navigator?.geolocation && !userLocation) {
			navigator.geolocation.getCurrentPosition(location => {
				if (location) {
					setUserLocation({
						lat: location.coords.latitude,
						lng: location.coords.longitude,
					});
				}
			});
		}
	}, [userLocation]);

	useEffect(() => {
		if (state?.establishmentCategories) {
			setEstablishmentCategories(state?.establishmentCategories);
		} else {
			getCategories();
		}
	}, [state?.establishmentCategories, getCategories]);

	useEffect(() => {
		if (state?.activeCategory) {
			setActiveGategory(state?.activeCategory);
		} else if (establishmentCategories?.length) {
			setActiveGategory(establishmentCategories?.[0]?.id);
		}
	}, [state?.activeCategory, establishmentCategories]);

	useEffect(() => {
		if (id) {
			getEstablishment({
				variables: {
					establishmentInput: {
						id: +id,
						lat: userLocation?.lat || 0,
						lng: userLocation?.lng || 0,
					},
				},
			});
		} else if (activeCategory) {
			const { city, ...filters } = establishmentFilters;
			getEstablishmentsList({
				variables: {
					establishmentListInput: {
						...filters, search: searchTerm, categoryIds: [activeCategory]
					},
				},
			});
		}
	}, [activeCategory, id, establishmentFilters, userLocation, searchTerm, getEstablishment, getEstablishmentsList]);

	useEffect(() => {
		if (activeCategory) {
			setEstablishmentFilters(INITIAL_ESTABLISHMENTS_FILTERS);
			getTagsList({
				variables: {
					filter: {
						idCategoria: [activeCategory],
					},
				},
			});
			getTypologiesList({
				variables: {
					getTypologyInput: {
						categoryIds: [activeCategory],
					},
				},
			});
			getFacilities();
			getDisabilities();
		}
	}, [activeCategory, getTagsList, getTypologiesList, INITIAL_ESTABLISHMENTS_FILTERS, getFacilities, getDisabilities]);

	const [addToFavourite] = useMutation(ADD_TO_FAVOURITE, {
		update(cache, { data }) {
			const cacheData = cache.readQuery({
				query: GET_ESTABLISHMENTS,
				variables: {
					establishmentListInput: { ...establishmentFilters, categoryIds: [activeCategory] },
				},
			});
			const updatedData = cacheData.establishmentsList.map(item => {
				if (+item?.id === +data.markAsFavorite.establishmentId) {
					return { ...item, isFavorite: true };
				} else return item;
			});

			cache.writeQuery({ query: GET_ESTABLISHMENTS, data: { ...cacheData, establishmentsList: updatedData } });
		},
	});

	const [removeFromFavourite] = useMutation(REMOVE_FROM_FAVOURITE, {
		update(cache, { data }) {
			const cacheData = cache.readQuery({
				query: GET_ESTABLISHMENTS,
				variables: {
					establishmentListInput: { ...establishmentFilters, categoryIds: [activeCategory] },
				},
			});
			const updatedData = cacheData.establishmentsList.map(item => {
				if (+item?.id === +data.unmarkFavorite.establishmentId) {
					return { ...item, isFavorite: false };
				} else return item;
			});

			cache.writeQuery({ query: GET_ESTABLISHMENTS, data: { ...cacheData, establishmentsList: updatedData } });
		},
	});

	return (
		<section className={styles.establishments__section}>
			{!favorites && (
				<SearchPanel
					isMobile={isMobile}
					myref={myref}
					establishmentId={id}
					establishmentCategories={establishmentCategories}
					activeCategory={activeCategory}
					userLocation={userLocation}
					setActiveGategory={setActiveGategory}
					tags={tags?.GetAllTagsAndFields?.tags}
					typologies={typologies?.establishmentTypologies}
					facilities={facilities}
					disabilities={disabilities}
					establishmentFilters={establishmentFilters}
					setEstablishmentFilters={setEstablishmentFilters}
					selectCity={setCity}
					setCityCoordinates={setCityCoordinates}
					city={city}
					searchTerm={searchTerm}  // Pass searchTerm
					setSearchTerm={setSearchTerm}  // Pass setSearchTerm
					searchAction={() => getEstablishmentsList({
						variables: {
							establishmentListInput: {
								...establishmentFilters, search: searchTerm, categoryIds: [activeCategory]
							},
						},
					})}  // Pass searchAction
				/>
			)}
			<Outlet
				context={{
					isMobile,
					myref,
					userLocation,
					isLoading: establishmentsListLoading || establishmentsDetailsLoading,
					establishmentsList: data?.establishmentsList?.filter(({ isFavorite }) => !favorites || isFavorite),
					establishmentsDetails,
					establishmentId: id,
					activeCategory,
					removeFromFavourite,
					addToFavourite,
					favorites,
					cityCoord: cityCoordinates,
				}}
			/>
		</section>
	);
};

export default EstablishmentsList;
