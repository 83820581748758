import clx from 'classnames';
import {
	useCallback, useEffect, useMemo, useState
} from 'react';
import { useNavigate } from 'react-router-dom';

import { axiosClient } from '../../../../../api/axiosClient';
import ArrowIcon from '../../../../../assets/svg/arrow.svg';
import QuestionMarkIcon from '../../../../../assets/svg/question-mark.svg';
import { ReactComponent as SettingsIcon } from '../../../../../assets/svg/settings.svg';
import { ROUTES } from '../../../../../routes/constants';
import IconButton from '../../../../Common/Buttons/IconButton';
import Carousel from '../../../../Common/Carousel';
import { RSelect } from '../../../../Common/Select';
import SkeletonLine from '../../../../Common/SkeletonLine';
import { ESTABLISHMENT_ICON_COLORS } from '../../constants';
import styles from './SearchPanel.module.scss';

const getCities = async () => {
	const { data } = await axiosClient.get('https://api.world4all.fun/city');
	return data;
};

const SearchPanel = ({
	myref,
	establishmentCategories,
	establishmentId,
	activeCategory,
	setActiveGategory,
	tags,
	typologies,
	facilities,
	disabilities,
	establishmentFilters,
	setEstablishmentFilters,
	userLocation,
	searchAction,
	selectCity,
	isMobile,
	city,
	setCityCoordinates,
	searchTerm, // Add searchTerm prop
	setSearchTerm // Add setSearchTerm prop
}) => {
	const navigate = useNavigate();
	const [isFilterListOpen, setIsFilterListOpen] = useState(false);
	const [cities, setCities] = useState([]);
	const [searchInput, setSearchInput] = useState(''); // Manage local search input state

	useEffect(() => {
		const fetchCities = async () => {
			const { city: allCities } = await getCities();
			const cities = allCities.map((value, index) => ({
				...value,
				value: index,
				label: value.nome.split(' ')[0],
			}));
			setCities(cities);
			setEstablishmentFilters(prevState => ({
				...prevState,
				lat: cities[city || 0].coord.lat,
				lng: cities[city || 0].coord.lng,
			}));
			setCityCoordinates(cities?.[city || 0]?.coord);
		};

		fetchCities();
	}, [city, setEstablishmentFilters, setCityCoordinates]);

	useEffect(() => {
		setSearchInput(searchTerm); // Update local search input state when searchTerm changes
	}, [searchTerm]);

	const handleSelectCity = useCallback(
		value => {
			selectCity(value);
			setEstablishmentFilters(prevState => ({
				...prevState,
				lat: cities[value].coord.lat,
				lng: cities[value].coord.lng,
			}));
			setCityCoordinates(cities[value].coord);
		},
		[cities, selectCity, setEstablishmentFilters, setCityCoordinates]
	);

	const handleFilterChange = useCallback(
		(type, id) => {
			if (establishmentId) {
				navigate(ROUTES.ESTABLISHMENTS);
			}
			setEstablishmentFilters(prevState => {
				const ids = prevState[type] || [];
				const updatedIds = ids.includes(id) ? ids.filter(item => item !== id) : [...ids, id];
				return {
					...prevState,
					[type]: updatedIds,
				};
			});
		},
		[establishmentId, navigate, setEstablishmentFilters]
	);

	const handleSearchChange = useCallback(
		event => {
			const input = event.target.value;
			setSearchInput(input);
			setSearchTerm(input); // Update parent component's searchTerm
			if (input.length >= 3) {
				searchAction(); // Trigger search action
			}
		},
		[searchAction, setSearchTerm]
	);

	const handleKeyDown = useCallback(
		event => {
			if (event.key === 'Enter' && searchInput.length >= 3) {
				searchAction(); // Trigger search action on Enter key press
			}
		},
		[searchInput, searchAction]
	);

	const handleSearchSubmit = useCallback(
		event => {
			event.preventDefault();
			if (searchInput.length >= 3) {
				searchAction(); // Trigger search action on form submission
			}
		},
		[searchInput, searchAction]
	);

	const categoryItems = useMemo(
		() =>
			(establishmentCategories?.length ? establishmentCategories : []).map(category => {
				const isActive = +activeCategory === +category?.id;
				return (
					<li
						key={category?.id}
						className={`${styles['category-item']} ${isActive ? styles['category-item__active'] : ''}`}
						style={{
							background: isActive ?
								ESTABLISHMENT_ICON_COLORS.find(item => +item.id === +category?.id)?.color || '#7A42FF' :
								'#203150',
						}}
						onClick={() => {
							setActiveGategory(category?.id);
							if (establishmentId) {
								navigate(ROUTES.ESTABLISHMENTS);
							}
							searchAction();
						}}
					>
						<img
							src={category?.url}
							alt="icon"
							onError={({ currentTarget }) => {
								currentTarget.onerror = null;
								currentTarget.src = QuestionMarkIcon;
							}}
						/>
						<span>{category?.name}</span>
					</li>
				);
			}),
		[establishmentCategories, activeCategory, setActiveGategory, establishmentId, navigate, searchAction]
	);
	const inputStyles = {
		backgroundColor: '#203150',
		border: 'none',
		padding: '10px 15px', // Increased padding for height
		color: '#ffffff',
		fontSize: '14px',
		fontWeight: '700',
		cursor: 'pointer',
		display: 'flex',
		borderRadius: '10px',
		transitionDuration: '1s',
		animationDuration: '1s',
		height: '60px', // Optional: fixed height for consistency
		position: 'relative',
		top: '15px',

	};

	return (
		<div className={`${styles['search-panel']} ${isMobile ? styles['search-panel__mobile'] : ''}`}>
			{isMobile && (
				<header>
					<IconButton iconSrc={ArrowIcon} onClick={() => navigate(ROUTES.HOME)} />
					<div className={`${styles.btn__settings} ${isFilterListOpen ? styles.settings__open : ''}`} onClick={() => setIsFilterListOpen(s => !s)}>
						<SettingsIcon />
					</div>
				</header>
			)}
			{/* <div className={styles['panel__categories']}>
				{!isMobile && <h3 className={styles['panel__title']}>Dove?</h3>}
				{cities && (
					<RSelect
						options={cities}
						selectedOption={cities[city]}
						setSelectedOption={handleSelectCity}
					/>
				)}
			</div>

			<hr /> */}
			<div className={styles.filters__wrapper}>
				<form onSubmit={handleSearchSubmit} className={styles.searchForm}>
					<input
						type="text"
						placeholder="Search establishments..."
						value={searchTerm}
						onChange={handleSearchChange}
						onKeyDown={handleKeyDown}
						style={inputStyles}
					/>
				</form>
				<hr/>
				<div className={styles.filters__list}>
					<h4>Disabilità</h4>
					<ul>
						{disabilities?.length ?
							(
								disabilities.map(disability => (
									<li key={disability?.id}>
										<label>
											<input
												id={`disability-${disability?.id}`}
												type="checkbox"
												checked={establishmentFilters?.disabilityIds?.includes(disability?.id) || false}
												onChange={() => handleFilterChange('disabilityIds', disability?.id)}
											/>
											<span className="checkmark"></span>
											<span className={styles.label}>{disability?.name}</span>
										</label>
									</li>
								))
							) :
							(
								<SkeletonLine width="100%" />
							)}
					</ul>
				</div>
				<div className={styles.filters__list}>
					<h4>facilità</h4>
					<ul>
						{facilities?.length ?
							(
								facilities.map(facility => (
									<li key={facility?.id}>
										<label>
											<input
												id={`facility-${facility?.id}`}
												type="checkbox"
												checked={establishmentFilters?.facilityIds?.includes(facility?.id) || false}
												onChange={() => handleFilterChange('facilityIds', facility?.id)}
											/>
											<span className="checkmark"></span>
											<span className={styles.label}>{facility?.name}</span>
										</label>
									</li>
								))
							) :
							(
								<SkeletonLine width="100%" />
							)}
					</ul>
				</div>
			</div>

			<hr />
			<div className={styles['panel__categories']}>
				{!isMobile && <h3 className={styles['panel__title']}>Categoria:</h3>}
				{establishmentCategories?.length ?
					(
						isMobile ?
							(
								<Carousel
									isMobile={isMobile}
									slides={establishmentCategories.map(category => {
										const isActive = +activeCategory === +category?.id;
										return (
											<div
												key={category?.id}
												className={`${styles['category-item']} ${isActive ? styles['category-item__active'] : ''}`}
												style={{
													background: isActive ?
														ESTABLISHMENT_ICON_COLORS.find(item => +item.id === +category?.id)?.color || '#7A42FF' :
														'#203150',
												}}
												onClick={() => {
													setActiveGategory(category?.id);
													if (establishmentId) {
														navigate(ROUTES.ESTABLISHMENTS);
													}
													searchAction();
												}}
											>
												<img
													src={category?.url}
													alt="icon"
													onError={({ currentTarget }) => {
														currentTarget.onerror = null;
														currentTarget.src = QuestionMarkIcon;
													}}
												/>
												<span>{category?.name}</span>
											</div>
										);
									})}
								/>
							) :
							(
								<ul className={styles['categories-list']}>{categoryItems}</ul>
							)

					) :
					(
						<SkeletonLine width="100%" />
					)}
			</div>
			<div className={clx(styles.filters__wrapper, { [styles['filters-wrapper__open']]: isMobile && isFilterListOpen })}>
				<h3 className={styles['panel__title']}>Filtra per:</h3>
				<div className={styles.filters__list}>
					<h4>Tipologia</h4>
					<ul>
						{typologies?.length ?
							(
								typologies?.map(typology => (
									<li key={typology?.id}>
										<label>
											<input
												id="typology"
												type="checkbox"
												ref={input => myref.current[typology?.id] = input}
												checked={establishmentFilters?.typologyIds?.includes(typology?.id)}
												onChange={() => {
													if (establishmentId) {
														navigate(ROUTES.ESTABLISHMENTS);
													}
													setEstablishmentFilters(prevState => ({
														...prevState,
														typologyIds: establishmentFilters?.typologyIds?.includes(+typology?.id) ?
															[...prevState?.typologyIds?.filter(t => +t !== +typology?.id)] :
															[...prevState?.typologyIds, +typology?.id],
													}));
												}}
											/>
											<span className="checkmark"></span>
											<span className={styles.label}>{typology?.name}</span>
										</label>
									</li>
								))
							) :
							(
								<SkeletonLine width="100%" />
							)}
					</ul>
				</div>
				<div className={styles.filters__list}>
					<h4>Posizione:</h4>
					<div className={styles.filter__radius}>
						<input
							id="position"
							type="range"
							min="2"
							max="50"
							disabled={!userLocation && !cities?.length}
							value={establishmentFilters?.radius || 49}
							style={{ backgroundSize: `${((establishmentFilters?.radius || 50) * 100) / 50}% 100%` }}
							onChange={e => {
								e.persist();
								if (establishmentId) {
									navigate(ROUTES.ESTABLISHMENTS);
								}
								if (e?.target?.value) {
									setEstablishmentFilters(prevState => ({
										...prevState,
										...userLocation,
										radius: +e?.target?.value,
									}));
								}
							}}
							step="1"
						/>
						<p>
							<span>2 km</span>
							<span>50 km</span>
						</p>
					</div>
				</div>
				<div className={styles.filters__list}>
					<h4>Servizi</h4>
					<ul>
						{tags?.length ?
							(
								tags?.map(tag => (
									<li key={tag?.idTag}>
										<label>
											<input
												id="tag"
												type="checkbox"
												checked={establishmentFilters?.tagIds?.includes(tag?.idTag) || false}
												onChange={() => {
													if (establishmentId) {
														navigate(ROUTES.ESTABLISHMENTS);
													}
													setEstablishmentFilters(prevState => ({
														...prevState,
														tagIds: prevState?.tagIds ?
															establishmentFilters?.tagIds?.includes(tag?.idTag) ?
																prevState?.tagIds?.filter(t => +t !== +tag?.idTag) :
																[...prevState?.tagIds, tag?.idTag] :
															[tag?.idTag],
													}));
												}}
											/>
											<span className="checkmark"></span>
											<span className={styles.label}>{tag?.name}</span>
										</label>
									</li>
								))
							) :
							(
								<SkeletonLine width="100%" />
							)}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default SearchPanel;
