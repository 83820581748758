import { Link } from 'react-router-dom';

import LogoImg from '../../../assets/svg/logo.svg';
import { ROUTES } from '../../../routes/constants';
import styles from './LogoLink.module.scss';

const LogoLink = ({
	height = '58px',
	style = {}
}) => (
	<Link to={ROUTES.HOME} className={styles.logo__link} style={style}>
		<img src={LogoImg} alt='logo' className={styles.logo}
			style={{
				height
			}} />
	</Link>
);

export default LogoLink;
