import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import ArrowIcon from '../../../../../../assets/svg/arrow.svg';
import PrimaryButton from '../../../../../Common/Buttons/PrimaryButton';
import SkeletonLine from '../../../../../Common/SkeletonLine';
import { SIGNUP_STEPS } from '../../../../constants';
import styles from '../../SignUp.module.scss';

const SubmitButton = forwardRef(({
	activeStep, setActiveStep, navigate, homePagePath, isLoading
}, ref) => {
	const changeStep = formRef => {
		const step = SIGNUP_STEPS.find(stepInfo => +activeStep === stepInfo.step);
		const formErrors = step.requiredFields.map(rField => {
			formRef.setFieldTouched(rField, true, true);

			if (!formRef.values[rField] || (step?.isArray && !formRef.values[rField].length)) {
				return 'isError';
			}
			return undefined;
		});

		if (!formErrors.includes('isError')) {
			setActiveStep(prev => prev + 1);
		}
	};

	return (
		<footer>
			{activeStep === 4 && <p className={styles.terms__link}>
				Cliccando su “crea utente” accetti&nbsp;<Link to={'/'}>termini e condizioni</Link>
			</p>}
			<PrimaryButton
				height='60px'
				width='100%'
				text={isLoading ?
					<SkeletonLine /> :
					(activeStep === 1 || activeStep === 2) ? 'Prosegui' : 'Salva il profilo'}
				onClick={() => {
					if (activeStep < 4) {
						changeStep(ref.current);
					} else {
						ref.current.handleSubmit();
					}
				}}
			/>
			{activeStep > 2 && <PrimaryButton
				height='60px'
				width='100%'
				background='#2C335B'
				text='No, lo farò dopo'
				onClick={() => {
					localStorage.setItem('disabilityIds', JSON.stringify(ref?.current?.values?.disabilityIds));
					localStorage.setItem('facilityIds', JSON.stringify(ref?.current?.values?.facilityIds));
					navigate(homePagePath);
				}}
			/>}
			{(activeStep === 2 || activeStep === 3) && <PrimaryButton
				height='60px'
				width='100%'
				background='transparent'
				textDecoration='underline'
				leftIconSrc={ArrowIcon}
				text='Indietro'
				onClick={() => setActiveStep(s => s - 1)}
			/>}
		</footer>
	);
});

export default SubmitButton;
