import './styles/index.scss';

import { useQuery } from '@apollo/client';
import React, { Suspense, useEffect } from 'react';
import {
	Navigate, Outlet, Route, Routes
} from 'react-router-dom';

import { GET_ME } from '../src/api/queries';
import { isAuthenticated } from './api/apolloClient';
import {
	ForgotPassword, SignIn, SignUp
} from './Containers/Auth/containers';
import { ResetPassword } from './Containers/Auth/containers/ResetPassword';
import PageWrapper from './Containers/Common/PageWrapper';
import Spinner from './Containers/Common/Spinner';
import EstablishmentsList from './Containers/pages/EstablishmentsList';
import EstablishmentDetails from './Containers/pages/EstablishmentsList/containers/EstablishmentDetails';
import Establishments from './Containers/pages/EstablishmentsList/containers/Establishments';
import Home from './Containers/pages/Home';
import ProfileSettings from './Containers/pages/ProfileSettings';
import Profile from './Containers/pages/ProfileSettings/containers/Profile';
import { ROUTES } from './routes/constants';
import { PrivateRoute } from './routes/containers';

const App = props => {
	const isUserLoggedIn = isAuthenticated();
	const { data, loading } = useQuery(GET_ME);

	useEffect(() => {
		if (data?.me) {
			localStorage.setItem('disabilityIds', JSON.stringify(data.me.disabilities?.map(disability => disability.id)));
			localStorage.setItem('facilityIds', JSON.stringify(data.me.facilities?.map(facility => facility.id)));
		}
	}, [data?.me]);

	return (
		<Suspense fallback={<Spinner />}>
			<main className="container">
				<Routes>
					<Route path={ROUTES.SIGN_IN} element={<SignIn />} />
					<Route path={ROUTES.SIGN_UP} element={<SignUp />} />
					<Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
					<Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />

					{/* Show EstablishmentsList if the user is not logged in */}
					{!isUserLoggedIn && (
						<Route path="/establishments-list" element={<EstablishmentsList />} />
					)}

					<Route
						element={
							<PageWrapper isLoading={loading} user={data?.me} isAuthenticated={isUserLoggedIn}>
								<Outlet />
							</PageWrapper>
						}
					>
						<Route path={ROUTES.ESTABLISHMENTS} element={<EstablishmentsList isAuthenticated={isUserLoggedIn} userFirstName={!loading && data?.me?.firstName} />} />
						<Route
							path={ROUTES.ESTABLISHMENTS}
							element={
								<EstablishmentsList>
									<Outlet />
								</EstablishmentsList>
							}
						>
							<Route index element={<Establishments />} />
							<Route path=":id" element={<EstablishmentDetails />} />
						</Route>
						<Route
							path={ROUTES.FAVORITES}
							element={
								<EstablishmentsList favorites={true}>
									<Outlet />
								</EstablishmentsList>
							}
						>
							<Route index element={<Establishments favorites={true} />} />
						</Route>
						<Route
							path={ROUTES.PROFILE}
							element={
								<PrivateRoute
									component={() => (
										<PageWrapper isLoading={loading} user={data?.me} isAuthenticated={isUserLoggedIn}>
											<ProfileSettings>
												<Outlet context={{ user: data.me }} />
											</ProfileSettings>
										</PageWrapper>
									)}
									isAuthenticated={isAuthenticated}
								/>
							}
						>
							<Route index element={<Profile />} />;
						</Route>
						<Route path="*" element={<Navigate to={ROUTES.ESTABLISHMENTS} />} />
					</Route>
				</Routes>
			</main>
		</Suspense>
	);
};

export default App;
