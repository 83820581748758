import { useMutation } from '@apollo/client';
import {
	Field, Form, Formik
} from 'formik';
import React, { useEffect, useState } from 'react';
import {
	Link, useNavigate, useParams
} from 'react-router-dom';

import { ROUTES } from '../../../../routes/constants';
import useWindowSize from '../../../../utils/useWindowSize';
import PrimaryButton from '../../../Common/Buttons/PrimaryButton';
import TextField from '../../../Common/TextField';
import { CHANGE_PASSWORD, CHECK_CODE } from '../../api/mutations';
import {
	RESET_PWD_VALIDATION_SCHEMA
} from '../../constants';
import AuthNavLink from '../AuthNavLink';
import AuthPageWrapper from '../AuthPageWrapper';
import SuccessPage from '../SuccessPage';
import styles from './ResetPassword.module.scss';

const ResetPassword = () => {
	const {
		isMobile, isMiddleSize
	} = useWindowSize();
	const [checkCode] = useMutation(CHECK_CODE);
	const [changePasswordMutation] = useMutation(CHANGE_PASSWORD);
	const [validCode, setIsValidCode] = useState(null);
	const { token } = useParams();
	const [isResetSuccess, setIsResetSuccess] = useState(false);


	useEffect(() => {
		const checkCodeMutation = async () => {
			try {
				const { data } = await checkCode({ variables: { code: token } });
				setIsValidCode(data.checkCode);
			} catch (error) {
				console.error('Error checking code:', error);
			}
		};

		checkCodeMutation();
	}, [checkCode, token]);

	const handleSubmit = async values => {
		const { password } = values;

		try {
			const { data } = await changePasswordMutation({
				variables: {
					changePasswordInput: {
						code: token,
						password,
					},
				},
			});

			// Show success message without navigating
			setIsResetSuccess(true);
		} catch (error) {
			console.error('Password change error:', error);
		}
	};

	return (
		<AuthPageWrapper isMobile={isMobile}>
			{validCode ?
				(
					isResetSuccess ?
						(
							<div style={{position: 'relative', bottom: '50%'}} className={styles.successMessage}>
								<h2>Password cambiata con successo!</h2>
							</div>
						) :
						(
							<Formik
								initialValues={{
									password: '',
									passwordConfirmation: '',
								}}
								validationSchema={RESET_PWD_VALIDATION_SCHEMA}
								onSubmit={handleSubmit}
							>
								{() => (
									<Form className={styles['forgot-pwd__form']}>
										{isMobile && (
											<Link to={ROUTES.SIGN_IN} className={styles.logo__link}></Link>
										)}
										<header>
											<h1>Recupero password</h1>
											<br/>
											{!isMobile && <p>Inserisci la tua nuova password, non sono ammessi incaratteri speciali.</p>}
										</header>
										<div className={styles.form__body}>
											<Field name="password" id="password" placeholder="Password"
												component={TextField} type="password" showPassword={true} />
											<Field name="passwordConfirmation" id="passwordConfirmation" type="password"
												placeholder="Ripeti password" component={TextField} showPassword={true} />
										</div>
										<footer>
											<PrimaryButton
												background='linear-gradient(232.71deg, #0eedff33 -23.85%, #ab74f933 109.37%)'
												color="#D4DBDE"
												fontSize="13px"
												height="50px"
												width="200px"
												padding="18px"
												text="AVANTI"
												type="submit"
											/>
										</footer>
										<br/>
										{isMobile && (
											<div className={styles['nav-link__wrapper']}>
												<AuthNavLink />
											</div>
										)}
									</Form>
								)}
							</Formik>
						)
				) :
				(
					<div style={{ transform: 'translateY(-350px)' }}>
						<h2>Opss<br/>
						assicurati che la password non contenga caratteri speciali</h2>
					</div>
				)}
		</AuthPageWrapper>
	);
};

export default ResetPassword;
