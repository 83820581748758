import clx from 'classnames';

import styles from './PrimaryButton.module.scss';

const PrimaryButton = ({
	background = 'linear-gradient(232.71deg, #0EEDFF -23.85%, #AB74F9 109.37%)',
	color = '#FFFFFF',
	height = '63px',
	width = 'auto',
	fontSize = '18px',
	padding = '20px',
	borderRadius = '16px',
	border,
	text,
	textTransform,
	textDecoration,
	leftIconSrc,
	rightIconSrc,
	onClick,
	className,
	type = 'button',
	disabled
}) => (
	<button
		style={{
			height,
			width,
			fontSize,
			borderRadius,
			border,
			padding,
			color,
			background
		}}
		className={clx(
			styles.primary__btn,
			styles[`${className}`],
		)}
		onClick={onClick}
		type={type}
		disabled={disabled}
	>
		{leftIconSrc && <img className={styles['left-icon']} src={leftIconSrc} alt='icon' />}
		<span
			style={{
				textTransform,
				textDecoration
			}}
		>{text}</span>
		{rightIconSrc && <img className={styles['right-icon']} src={rightIconSrc} alt='icon' />}
	</button>
);

export default PrimaryButton;
