import { gql } from '@apollo/client';

export const GET_ME = gql`
	query me {
		me {
			id
			firstName
			accompanied
			lastName
			email
			dateBirth
			phone
			role
			avatar {
				id
				url
			}
			createdAt
			updatedAt
			isActive
			address {
				id
				country
				city
				street
				buildingNumber
				apartment
			}
			disabilities {
				id
				name
				isDisabled
			}
			facilities {
				id
				name
				isDisabled
			}
		}
	}
`;
